import * as React from "react";


function TurnRoad1() {


    return (
        <svg

            viewBox="0 0 1000 1000"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">

        <rect x="1000.5" y="0.5" width="1000" height="1000" transform="rotate(90 1000.5 0.5)" fill="#808080"/>
        <rect x="1001.5" y="73.5" width="945" height="30" transform="rotate(-180 1001.5 73.5)" fill="#F5F5F5"/>
        <rect x="1001.5" y="528.5" width="502" height="30" transform="rotate(-180 1001.5 528.5)" fill="#E5F43B"/>
        <rect x="1000.5" y="955.5" width="74" height="30" transform="rotate(-180 1000.5 955.5)" fill="#F5F5F5"/>
        <rect x="1000.5" y="955.5" width="74" height="30" transform="rotate(-180 1000.5 955.5)" fill="#F5F5F5"/>
        <rect x="926.5" y="1000.5" width="49" height="30" transform="rotate(-90 926.5 1000.5)" fill="#F5F5F5"/>
        <rect x="926.5" y="1000.5" width="49" height="30" transform="rotate(-90 926.5 1000.5)" fill="#F5F5F5"/>
        <path d="M529.5 501.5L529.5 1000.5H499.5L499.5 501.5H529.5Z" fill="#E5F43B"/>
        <rect x="74.5" y="43.5" width="957" height="30" transform="rotate(90 74.5 43.5)" fill="#F5F5F5"/>

        </svg>
    );
}

export default TurnRoad1;
