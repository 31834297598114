import React, {createContext, useEffect, useMemo, useRef, useState} from 'react';
import './App.css';
import Car from "./comps/Car";
import Log from "./comps/Log";
import TrafficLight from './comps/trafficLight';
import MapSvg from './comps/MapSvg';
import {ICars} from "./Types/Cars"
import {ILogs} from "./Types/Logs";
import {getCityState, pingPong, subscribe_listener, updateDoc, updateComp, poke} from './ws/ws';
import {IIncidents} from "./Types/Incidents";
import Pont from './comps/Pont';
import {ITile} from "./Types/Tile";
import {Link} from "react-router-dom";
import {useTranslation} from 'react-i18next';
import {defaultMap, defaultIncidents} from "./constantes";
import {t} from "i18next";


const BridgeContext = createContext("closed");
const TrafficLightsContext = createContext({});

function App() {
    const {t} = useTranslation('translation');

    const [bridgeState, setBridgeState] = useState<string>(`${t("open")}`)
    const [modeState, setModeState] = useState<string>(`${t("manual")}`)

    const {i18n} = useTranslation();

    useEffect(() => {

        if (i18n.language === 'ar') {
            document.body.dir = 'rtl';
        } else {
            document.body.dir = 'ltr';
        }
    }, [i18n.language]);


    const logsList = useMemo(() => [
        {
            id: 12,
            content: `${t('logs_message')}`,
            timestamp: "2024-02-29 19:55",
        },
    ], [t]);


    const [flagConnection, setFlagConnection] = useState<boolean>(false)

    const [showTowersFlag, setShowTowersFlag] = useState<boolean>(true)
    const [showTriangulationFlag, setShowTriangulationFlag] = useState<boolean>(false)

    const [streamLink, setStreamLink] = useState<string>("")
    const [streamState, setStreamState] = useState<null | boolean>(false)
    const [streamId, setStreamId] = useState<null | number>(null)
    const [logs, setLogs] = useState<Array<ILogs>>([])
    const [map, setMap] = useState<Array<ITile>>([])
    const [cars, setCars] = useState<Array<ICars>>([])
    const [incidents, setIncidents] = useState<Array<IIncidents>>([])
    const [trafficLight, setTrafficLight] = useState<object>({})

    const [addMapPrompt, setAddMapPrompt] = useState<boolean>(false)

    const [newLog, setNewLog] = useState<string>("")


    useEffect(() => {
        pingPong(setFlagConnection, setBridgeState, setModeState, setMap, setTrafficLight, setIncidents, setCars)
        try{poke()}catch(e){}
        setLogs(logsList);
    }, [logsList])

    useEffect(() => {
        if(flagConnection){
            getCityState()
                .then((data) => {
                    if (!(data === null) && data.map === undefined && data.incidents === undefined) {
                        setAddMapPrompt(true)
                    }
                    setMap(data.map)
                    setIncidents(data.incidents)
                    if (data.trafficLights) setTrafficLight(data.trafficLights)
                    setCars(data.voiture)
                    if (data.pont) setBridgeState(data.pont)
                    subscribe_listener();
                })
                .catch((error) => {
                    console.error(error);
                })
        }
    }, [flagConnection])


    function changeMode() {
        let nMode = "";
        if (modeState === t('manual')) {
            nMode = t('automatic')
        } else {
            nMode = t('manual');
        }
        setModeState(nMode)
        updateDoc({"/doc/mode": nMode});
    }

    // keep add log input value up to date
    function handleChangeLog(e: React.FormEvent<HTMLInputElement>) {
        setNewLog(e.currentTarget.value)
    }

    // add log to list
    function addLog() {
        if (newLog) {
            const date = new Date()
            const timestamp =
                String(date.getFullYear()) + "-" +
                String(date.getMonth() + 1).padStart(2, '0') + "-" +
                String(date.getDate()).padStart(2, '0') + " " +
                String(date.getHours()).padStart(2, '0') + ":" +
                String(date.getMinutes()).padStart(2, '0')
            const log: ILogs = {
                id: logs.length + 1,
                content: newLog,
                timestamp: timestamp
            }
            logsList.push(log);
            console.log(logsList)
            setLogs(logs.concat(log))
            updateComp("mylog", log.content);

        }
    }

    // function pour ouvrir et fermer le pont manuellement
    function changerPont() {
        let pont = "";
        if (bridgeState === t('open')) {
            pont = t('closed')
        } else {
            pont = t('open')
        }
        setBridgeState(pont)
        updateDoc({"/doc/pont": pont})
    }


    const [coordX, setCoordX] = useState<number>(0)
    const [coordY, setCoordY] = useState<number>(0)

    function handleChangeX(e: React.ChangeEvent<HTMLInputElement>) {
        setCoordX(Number(e.currentTarget.value))
    }

    function handleChangeY(e: React.ChangeEvent<HTMLInputElement>) {
        setCoordY(Number(e.currentTarget.value))
    }

    async function addIncident() {
        if (coordX && coordY) {
            const incList = incidents ? incidents : []
            const newIncident = {
                id: incidents && incidents.length > 0 ? incidents.length + 1 : 0,
                x: coordX,
                y: coordY,
                type: "generic"
            };
            incList.push(newIncident)
            // Update incidents state
            setIncidents(incList)
            await updateDoc({"/doc/incidents": incList})

            // Generate timestamp for the incident
            const date = new Date();
            const timestamp =
                String(date.getFullYear()) + "-" +
                String(date.getMonth() + 1).padStart(2, '0') + "-" +
                String(date.getDate()).padStart(2, '0') + " " +
                String(date.getHours()).padStart(2, '0') + ":" +
                String(date.getMinutes()).padStart(2, '0');

            // Create a log message for the incident
            const logMessage = `${t('incident_logs')} ${coordX}, ${coordY}`;

            // Create a new log object
            const newLog: ILogs = {
                id: logs.length + 1,
                content: logMessage,
                timestamp: timestamp
            };

            // Update logs state
            setLogs([...logs, newLog]);

            updateComp("mylog", logMessage);
        }
    }


    async function addDefaultMap() {
        try {
            setAddMapPrompt(false);
            await updateDoc({"/doc/map": defaultMap, "/doc/incidents": defaultIncidents});
            await addIncident();  // Ensuring that addIncident completes before continuing
        } catch (error) {
            console.error("Error updating document or adding incident:", error);
        }
    }


    return (
        <div className="grid grid-cols-10 h-full w-full overflow-y-hidden">


            {/* Map Container */}
            <div className="col-span-5 row-span-2 flex flex-col h-screen pb-16">
                <div className="bg-blue-400 rounded-md m-2 p-2 h-full ">
                    <div className="flex flex-col flex-1 h-full">
                        <div>
                            <div className="flex justify-between">
                                <div>
                                    <button className="bg-blue-300 hover:bg-blue-500 font-bold py-2 px-4 mr-2 rounded"
                                            onClick={() => {
                                                setShowTowersFlag(!showTowersFlag);
                                                setShowTriangulationFlag(false)
                                            }}>
                                        {showTowersFlag ? t('buttons.hide_tow') : t('buttons.show_tow')}
                                    </button>
                                    {showTowersFlag &&
                                        <button className="bg-blue-300 hover:bg-blue-500 font-bold py-2 px-4 rounded"
                                                onClick={() => {
                                                    setShowTriangulationFlag(!showTriangulationFlag)
                                                }}>
                                            {showTriangulationFlag ? t('buttons.hide_triang') : t('buttons.show_triang')}
                                        </button>}
                                </div>
                                <div className="flex justify-end ">
                                    <label>
                                        <span className="font-bold mr-2">X: </span>
                                        <input onChange={handleChangeX} type="number" className="p-2 rounded w-20"/>
                                    </label>
                                    <label className="mx-4">
                                        <span className="font-bold mr-2">Y: </span>
                                        <input onChange={handleChangeY} type="number" className="p-2 rounded w-20"/>
                                    </label>
                                    <button onClick={addIncident}
                                            className="bg-white hover:bg-gray-200 font-bold py-2 px-4 rounded">{t('report_incident')}
                                    </button>
                                </div>
                            </div>
                            <p className="text-blue-400">map goes here</p>
                            <div className="flex justify-center my-2">
                                {map && map.length > 0 ? (
                                    <BridgeContext.Provider value={bridgeState}>
                                        <TrafficLightsContext.Provider value={trafficLight}>
                                            <MapSvg
                                                map={map}
                                                incidents={incidents}
                                                cars={cars}
                                                showTowersFlag={showTowersFlag}
                                                showTriangulationFlag={showTriangulationFlag}
                                            />
                                        </TrafficLightsContext.Provider>
                                    </BridgeContext.Provider>
                                ) : (
                                    <p className="font-bold">{t('placeholder')}</p>
                                )}
                            </div>
                            <div className="flex justify-center my-2">
                                {addMapPrompt &&
                                    <div>
                                        <button className="bg-blue-300 hover:bg-blue-500 font-bold py-2 px-4 rounded"
                                                onClick={addDefaultMap}>
                                            {t('buttons.default_map')}
                                        </button>
                                    </div>}
                            </div>
                        </div>
                        <div className="grow flex justify-end items-end">
                            <Link to="/mapConfig">
                                <button className="bg-blue-300 hover:bg-blue-500 font-bold py-2 px-4 rounded">
                                    {t('configure_map')}
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>


            {/* Cars Container */}
            <div className="col-span-2 row-span-2 flex flex-col h-screen pb-16 ">
                <div className="bg-blue-400 rounded-md m-2 p-2 flex flex-col h-full overflow-clip">
                    <div className="font-bold">{t('cars')}</div>
                    <div className="overflow-y-scroll mt-2">
                        {cars &&
                            cars.map((c) => {
                                return <Car
                                    key={c.id}
                                    car={c}
                                    setStreamLink={setStreamLink}
                                    setStreamState={setStreamState}
                                    setStreamId={setStreamId}
                                    streamId={streamId}
                                ></Car>
                            })
                        }
                    </div>
                </div>
            </div>


            {/* 3rd column container*/}
            <div className="col-span-3 row-span-2 flex flex-col h-screen pb-16">

                {/* Logs Container */}
                {streamState === false &&
                    <div className="bg-blue-400 rounded-md m-2 p-2 flex flex-col justify-between h-[calc(50%-2rem)]">
                        {t('logs_title')}
                        <div className="overflow-y-scroll my-2">
                            {logs && <div>{
                                logs.map((l) => {
                                    return <Log key={l.id} log={l}></Log>
                                })
                            }</div>}
                        </div>
                        <div className="flex flex-row justify-between">
                            <input onChange={handleChangeLog} className="bg-blue-200 rounded px-2 mr-2 grow"/>
                            <button onClick={addLog}
                                    className="bg-blue-300 hover:bg-blue-500 font-bold py-2 px-4 rounded grow-0">
                                {t('add_log')}
                            </button>
                        </div>
                    </div>}
                {/* Stream Container */}
                {streamState === true &&
                    <div className="bg-blue-400 rounded-md m-2 p-2 flex flex-col justify-between h-[calc(50%-2rem)]">
                        {t('stream_title')}
                        <div>
                            <img src={streamLink} width="100%" alt={streamLink}/>
                        </div>
                    </div>
                }

                {/* Infras Container */}
                <div className="bg-blue-400 rounded-md m-2 p-2 h-1/2 flex flex-col">
                    <div>
                        <div className="flex items-center"> {/* Container for label and toggle */}
                            <label className="switch mr-2"> {/* Toggle button */}
                                <input type="checkbox" onClick={changeMode}/>
                                <span className="slider round"></span>
                            </label>
                            <span>  {t('mode')} {modeState}</span> {/* Label */}
                        </div>

                        <div className='flex justify-center'>

                            {/* pont */}
                            <button onClick={() => {
                                if (modeState === t('manual')) changerPont()
                            }}>
                                <Pont></Pont>
                            </button>

                        </div>

                        <h1 className="text-center text-xl font-bold mb-4">{t('traffic_lights')}</h1>
                    </div>
                    <div className={"overflow-y-scroll"}>
                        {trafficLight && Object.keys(trafficLight).map((key, index) => {
                            if(key!== "") {
                                return <TrafficLight mode={modeState} keys={key} lights={trafficLight}
                                                     setLights={setTrafficLight} index={index}
                                                     lenght={Object.keys(trafficLight).length}/>
                            } else if(key === ""){
                                let cleanTL = trafficLight
                                delete cleanTL["" as keyof typeof trafficLight]
                                setTrafficLight(cleanTL)
                            }
                        })}
                    </div>

                </div>

            </div>

        </div>
    );
}

export {App, BridgeContext, TrafficLightsContext};
