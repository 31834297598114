import * as React from "react";
import {useContext} from "react";
import {BridgeContext} from "../../App";
import {useTranslation} from "react-i18next";


export default function BridgeRight() {

    const bridge = useContext(BridgeContext);
    const { t } = useTranslation('translation');


    return (



        <svg viewBox="0 0 1000 1000" xmlns="http://www.w3.org/2000/svg">

            {/* open means open for cars to drive on */}
            <g transform={"rotate(-90 500 500)"}>
                <rect width="100%" height="100%" fill="#0f5e9c"/>
                {!(bridge === t('open')) && (
                    <svg width="599" height="1000" viewBox="0 0 599 1000" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.5 182L599 0V1000L0.5 818V182Z" fill="#D9D9D9"/>
                    </svg>
                )}

                {bridge === t('open') && (
                    <svg viewBox="0 0 1000 1000" xmlns="http://www.w3.org/2000/svg">
                        <rect width="1000" height="636" x={0} y={182} fill="#D9D9D9"/>
                    </svg>
                )}
            </g>


        </svg>
    )
};
