import React, {useEffect} from 'react';
import { ICars } from '../Types/Cars';
import {t} from "i18next";
import {useTranslation} from "react-i18next";

// Interface définissant la structure attendue de l'objet 'car'
interface CarProps {
    car: ICars;
    setStreamLink: (link: string) => void;
    setStreamState: (state: boolean) => void;
    setStreamId: (id: number) => void;
    streamId: number | null;
}

// Définition du composant fonctionnel Car prenant une propriété 'car'
function Car({ car, setStreamLink, setStreamState, setStreamId, streamId }: CarProps) {
    const { t, i18n } = useTranslation();
    // Extraction de la propriété 'car' pour plus de clarté dans le code
    const cars: ICars = car;

    // Vitesse maximale fixée à 100 km/h
    const maxSpeed = 100;

    useEffect(() => {

        if (i18n.language === 'ar') {
            document.body.dir = 'rtl';
        } else {
            document.body.dir = 'ltr';
        }
    }, [i18n.language]);

    // Fonction pour calculer la longueur de l'arc dans la jauge de vitesse
    const calculateArc = () => {
        const percentage = (cars.speed / maxSpeed) * 100;
        const angle = (percentage * 180) / 100;
        const radius = 45;
        const circumference = Math.PI * radius;
        const arcLength = (angle * circumference) / 180;

        return {
            strokeDasharray: `${arcLength} ${circumference}`,
        };
    };

    // Fonction pour déterminer la direction en fonction des coordonnées x, y
    const determineDirection = (x: number, y: number) => {
        const midX = 62.5; // (0 + 125) / 2 = le centre
        const midY = 50;   // (0 + 100) / 2 = le centre

        if (x < midX) {
            if (y < midY) {
                return t('north_west');
            } else if (y > midY) {
                return t('south_west');
            } else {
                return t('west');
            }
        } else if (x > midX) {
            if (y < midY) {
                return t('north_east');
            } else if (y > midY) {
                return t('south_east');
            } else {
                return t('east');
            }
        } else {
            if (y < midY) {
                return t('north');
            } else if (y > midY) {
                return t('south');
            } else {
                return t('center');
            }
        }
    };
    const showStream = () => {
        setStreamState(true); // Assuming 'streamState' can be "ON" or something else
        cars.streamState = true
        setStreamLink(cars.linkStream); // Assuming 'streamLink' is the property containing the link
        setStreamId(cars.id)
    };
    const closeStream = () =>{
        setStreamState(false)
        cars.streamState = false
    }
    //const [x, y] = car.position.split(',').map(Number);
    const direction = determineDirection(cars.x, cars.y);

    // Rendu du composant avec des éléments JSX
    return (
        <div className="shadow-lg p-4 bg-white/50 rounded-md mb-2 mr-2 overflow-hidden">
            <div className="text-center mb-2">
                <h2 className="text-lg font-bold">{t('car')} {cars.id}</h2>
            </div>
            <div className="text-center mb-2">
                {/* Jauge de pression dessinée */}
                <div className="relative w-full md:w-40 h-24 mx-auto">
                    <svg className="absolute" viewBox="0 0 120 50">
                        {/* Cercle de fond de la jauge */}
                        <path
                            d="M 10 50 A 50 45 0 0 1 110 50"
                            fill="transparent"
                            strokeWidth="10"
                            stroke="#ddd"
                        />
                        {/* Arc coloré représentant la vitesse de la voiture */}
                        <path
                            d="M 10 50 A 50 45 0 0 1 110 50"
                            fill="transparent"
                            strokeWidth="10"
                            stroke="#3498db"
                            style={calculateArc()} // Application de la longueur de l'arc calculée
                        />
                    </svg>
                    {/* Affichage de la vitesse au centre de la jauge */}
                    <div className="absolute inset-0 flex items-center justify-center">
                        <p className="text-xl font-bold">{cars.speed} {t('km_h')}</p>
                    </div>
                </div>
            </div>

            <div className="">{t('position')}: {cars.x}, {cars.y}</div>
            <div className="">{t('direction')}: {direction}</div>
            <div>
                {(!cars.streamState || streamId!==cars.id) &&
                    <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded center" onClick={showStream}>
                    {t('buttons.show_stream')}
                </button>}
                {cars.streamState && cars.id===streamId &&
                    <button className="bg-red-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded center" onClick={closeStream}>
                    {t('buttons.close_stream')}
                </button>}
            </div>
        </div>
    );
}

// Export du composant Car comme export par défaut du module
export default Car;
