import * as React from "react";
import {TileProps} from "../Types/TileProps";
import {ITile} from "../Types/Tile";


export default function Tower(props: TileProps) {
    const til: ITile = props.tile
    const onSelected: (x: number, y: number) => void = props.onSelected
    const tileSize = props.tileSize;

    return (
        <svg
            onClick={() => onSelected(til.x, til.y)}
            x={til.x * tileSize}
            y={til.y * tileSize}
            width={tileSize/2}
            height={tileSize/2}
            viewBox="0 0 15 13"
            xmlns="http://www.w3.org/2000/svg"
        >

            {/*<g transform="rotate(-45 7.5, 13)">*/}
            <path fill="black"
                  d="m11.855 6.434-.414-.282a4.76 4.76 0 0 0 .282-4.878l-.084-.153.436-.246.09.167a5.26 5.26 0 0 1-.31 5.392m1.152 7.131V14h-11v-.435h2.488L6.044 6.4a.5.5 0 0 1 .489-.394h.725V4.6a1.14 1.14 0 0 1-.882-1.1 1.157 1.157 0 1 1 2.313 0 1.14 1.14 0 0 1-.93 1.111v1.395h.721a.5.5 0 0 1 .49.394l1.547 7.165ZM8.454 8.751H6.56l-.323 1.493h2.541Zm-2.326 1.993-.323 1.495h3.403l-.323-1.495Zm.808-3.738-.27 1.245h1.68l-.269-1.245Zm-1.418 6.56h3.977l-.179-.827h-3.62ZM5.21 5.013a2.75 2.75 0 0 1 .016-3.052l-.414-.28a3.25 3.25 0 0 0-.019 3.607ZM10.757 3.5a3.24 3.24 0 0 0-.534-1.786l-.418.275a2.75 2.75 0 0 1-.018 3.05l.414.278a3.23 3.23 0 0 0 .556-1.817M3.534 6.118a4.76 4.76 0 0 1-.153-4.988L2.948.88a5.26 5.26 0 0 0 .17 5.514Z"/>
            {/*</g>*/}

        </svg>
    )
}
