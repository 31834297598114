import * as React from "react";


function HorizentalRoad1() {


    return (
        <svg

            viewBox="0 0 1000 1000"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">

<rect x="998.583" width="999.92" height="997.249" transform="rotate(89.9954 998.583 0)" fill="#808080"/>
<rect x="958.356" y="471.03" width="29.9976" height="149.587" transform="rotate(89.9954 958.356 471.03)" fill="#E5F43B"/>
<rect x="769.876" y="471.03" width="29.9976" height="149.587" transform="rotate(89.9954 769.876 471.03)" fill="#E5F43B"/>
<rect x="561.451" y="471.03" width="29.9976" height="149.587" transform="rotate(89.9954 561.451 471.03)" fill="#E5F43B"/>
<rect x="353.026" y="471.03" width="29.9976" height="149.587" transform="rotate(89.9954 353.026 471.03)" fill="#E5F43B"/>
<rect x="144.601" y="471.032" width="29.9976" height="133.631" transform="rotate(89.9954 144.601 471.032)" fill="#E5F43B"/>
<rect x="997.249" y="44.0015" width="29.9976" height="997.249" transform="rotate(89.9954 997.249 44.0015)" fill="#F5F5F5"/>
<rect x="999.998" y="926.291" width="29.9976" height="997.249" transform="rotate(89.9954 999.998 926.291)" fill="#F5F5F5"/>
<rect x="998.583" width="999.92" height="997.249" transform="rotate(89.9954 998.583 0)" fill="#808080"/>
<path d="M999.597 498.92L999.599 528.92L2.37557 529L2.37323 499L999.597 498.92Z" fill="#E5F43B"/>
<rect x="997.249" y="44.0015" width="29.9976" height="997.249" transform="rotate(89.9954 997.249 44.0015)" fill="#F5F5F5"/>
<rect x="999.998" y="926.291" width="29.9976" height="997.249" transform="rotate(89.9954 999.998 926.291)" fill="#F5F5F5"/>

        </svg>
    );
}

export default HorizentalRoad1;
