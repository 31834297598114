import React, {useState, useEffect} from 'react';
import {t} from "i18next";
import {updateDoc} from "../ws/ws";

function TrafficLight({mode, keys, lights, setLights, index, length}: any): JSX.Element {

    const [intervalId, setIntervalId] = useState<NodeJS.Timeout>();
    const [secondsLeft, setSecondsLeft] = useState(5);

    const [north, setNorth] = useState(lights[keys].N);
    const [east, setEast] = useState(lights[keys].E);
    const [south, setSouth] = useState(lights[keys].S);
    const [west, setWest] = useState(lights[keys].W);

    let state: number;
    if ((north === "green" && east === "red") || (south === "green" && west === "red")) {
        state = 0
    } else if ((north === "yellow" && east === "red") || (south === "yellow" && west === "red")) {
        state = 1
    } else if ((north === "red" && east === "green") || (south === "red" && west === "green")) {
        state = 2
    } else if ((north === "red" && east === "yellow") || (south === "red" && west === "yellow")) {
        state = 3
    } else {
        state = -1
    }


    function cycleLights() {
        // Update the traffic light state based on the current state
        state = (state + 1) % 4

        let ns = "red";
        let eo = "red";

        switch (state) {
            case 0:
                ns = "green"
                eo = "red"
                break;
            case 1:
                ns = "yellow"
                eo = "red"
                break;
            case 2:
                ns = "red"
                eo = "green"
                break;
            case 3:
                ns = "red"
                eo = "yellow"
                break;
            case -1:
                ns = "red"
                eo = "red"
                console.log("queue 'were completely f*cked.ogg'")
                break;
        }

        // Update the local state for each field
        let temp = lights
        temp[keys] = {E: eo, N: ns, S: ns, W: eo}
        setLights(temp)
        setEast(eo);
        setNorth(ns);
        setSouth(ns);
        setWest(eo);

        // Send a request to update the server with respective numeric values for each field
        if((mode === t('automatic') && index === 0) || mode === t("manual")) {
            updateDoc({
                '/doc/trafficLights': lights,
            });
        }
    }

    const handleClick = () => {
        if (mode === t('manual')) {
            cycleLights()
        }
    };


    function counter() {
        cycleLights()
        return 5
    }

    useEffect(() => {
        if (mode === t('automatic')) {
            const id = setInterval(() => {
                setSecondsLeft(prev => prev > 1 ? prev - 1 : counter())
            }, 1000);
            setIntervalId(id)
        }

        if (mode === t('manual')) {
            clearInterval(intervalId);
        }
    }, [mode]);

    return (
        <div>
                <h1 className={"font-bold text-center justify-center"}>{keys}</h1>
                <div className="flex justify-center traffic-light">
                    {/* east */}
                    <button className="traffic-light-item" onClick={() => handleClick()}>
                        <p className="text-center text-xs mb-1">{t('east')}</p>
                        <SVGComponent fill={
                            east === "red" ? "#ed4c5c" : east === "yellow" ? "#ffe62e" : east === "green" ? "#83bf4f" : ""}/>
                    </button>

                    {/* north */}
                    <button className="traffic-light-item" onClick={() => handleClick()}>
                        <p className="text-center text-xs mb-1">{t('north')}</p>
                        <SVGComponent
                            fill={north === "red" ? "#ed4c5c" : north === "yellow" ? "#ffe62e" : north === "green" ? "#83bf4f" : ""}/>
                    </button>

                    {/* south */}
                    <button className="traffic-light-item" onClick={() => handleClick()}>
                        <p className="text-center text-xs mb-1">{t('south')}</p>
                        <SVGComponent
                            fill={south === "red" ? "#ed4c5c" : south === "yellow" ? "#ffe62e" : south === "green" ? "#83bf4f" : ""}/>
                    </button>

                    {/* west */}
                    <button className="traffic-light-item" onClick={() => handleClick()}>
                        <p className="text-center text-xs mb-1">{t('west')}</p>
                        <SVGComponent
                            fill={west === "red" ? "#ed4c5c" : west === "yellow" ? "#ffe62e" : west === "green" ? "#83bf4f" : ""}/>
                    </button>
                </div>
                {mode === t('automatic') &&
                    <p className="text-center text-xs mt-2">{t('time_remaining', {secondsLeft})}</p>}
            </div>
    );
}


const SVGComponent = ({fill}: any) => {
    // couleur pour chaque feu
    const colors = ["#83bf4f", "#ffe62e", "#ed4c5c"];

    return (
        <svg
            width="60px"
            height="80px"
            viewBox="0 0 64 64"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            aria-hidden="true"
            role="img"
            className="iconify iconify--emojione"
            preserveAspectRatio="xMidYMid meet"
        >
            <path
                d="M32 2c-8.2 0-15 6.8-15 15v30c0 8.3 6.8 15 15 15s15-6.7 15-15V17c0-8.2-6.8-15-15-15"
                fill="#94989b"
            />
            <path
                d="M32 3.9c-7.2 0-13.1 5.9-13.1 13.1v30c0 7.2 5.9 13.1 13.1 13.1S45.1 54.2 45.1 47V17c0-7.2-5.9-13.1-13.1-13.1"
                fill="#3e4347"
            />
            {colors.map((color, index) => (
                <circle
                    key={index}
                    cx={32}
                    cy={32 + (index - 1) * 20} // Adjust the vertical position of each circle
                    r={7.5}
                    fill={fill === color ? color : "gray"}
                />
            ))}
        </svg>
    );
};


export default TrafficLight;

