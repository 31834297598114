import * as React from "react";
import {ITile} from "../Types/Tile";
import {TileProps} from "../Types/TileProps";
import {fetchTile} from "../utils"
import RenderSigns from "../utils/RenderSings";


function Placeholder(props: TileProps) {
    const til: ITile = props.tile
    const onSelected: (x: number, y: number) => void = props.onSelected
    const tileSize = props.tileSize;


    function renderTileComponent(tile: ITile) {
        return fetchTile(tile.tile)
    }


    return (
        <svg
            onClick={() => onSelected(til.x, til.y)}
            x={til.x * tileSize}
            y={til.y * tileSize}
            width={tileSize}
            height={tileSize}
            viewBox="0 0 1000 1000"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>

            {/* invisible rect to make whole square clickable */}
            <rect width="100%" height="100%" fill="red" opacity="0"/>



            {renderTileComponent(til)}
            {til.signs && RenderSigns(til)}


        </svg>
    );
}

export default Placeholder;
