// Importe React et les types nécessaires
import React from 'react';
import {ILogs} from "../Types/Logs";

// Définit le type pour LogProps
interface LogProps {
    log: ILogs;  // Prop représentant un objet log de type ILogs
}

// Définit le composant fonctionnel nommé Log qui prend LogProps en paramètre
function Log(props: LogProps) {
    const log: ILogs = props.log;  // Extrait l'objet log des props

    // Rend le composant avec une div stylisée avec ombre, padding, fond et coins arrondis.
    // Affiche le timestamp et le contenu du log à l'intérieur de la div.
    return (
        <div className="shadow-lg p-2 bg-white/50 rounded-md mb-2 mr-2">
            <div className="">{log.timestamp} : {log.content}</div>
        </div>
    );
}

export default Log;