import {ITile} from "./Tile";

export interface TileProps {
    tile: ITile;
    onSelected: (x: number, y: number) => void;
    tileSize: number;
}


export function resizeTiles(map: Array<ITile>, padding: number) {
    if(map.length > 1) {
        const maxX = map.reduce((prev, current) => (prev && prev.x > current.x) ? prev : current)
        const minX = map.reduce((prev, current) => (prev && prev.x < current.x) ? prev : current)
        const maxY = map.reduce((prev, current) => (prev && prev.y > current.y) ? prev : current)
        const minY = map.reduce((prev, current) => (prev && prev.y < current.y) ? prev : current)

        const sizeX = 5000 / (maxX.x - minX.x + 1 + padding)
        const sizeY = 5000 / (maxY.y - minY.y + 1 + padding)

        return Math.min(sizeX, sizeY)
    } else return 1000
}
