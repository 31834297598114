import * as React from "react";
import {ICars} from "../Types/Cars";

interface CarProps {
    car: ICars;
    tileSize: number
    setSelectedCarId: any

}

function SVGComponent(props: CarProps) {

    const car: ICars = props.car;
    const tileSize = props.tileSize
    const setSelectedCarId = props.setSelectedCarId

    return (
        <svg
            x={car.x * tileSize - (tileSize / 4)}
            y={car.y * tileSize - (tileSize / 4)}
            fill="#000000"
            width={tileSize}
            height={tileSize}
            viewBox="0 0 32 32"
            xmlns="http://www.w3.org/2000/svg"
            id="car"
            {...props}>
            <path
                d="M13.84,6.852,12.6,5.7,11.5,3.5a1.05,1.05,0,0,0-.9-.5H4.4a1.05,1.05,0,0,0-.9.5L2.4,5.7,1.16,6.852A.5.5,0,0,0,1,7.219V11.5a.5.5,0,0,0,.5.5h2c.2,0,.5-.2.5-.4V11h7v.5c0,.2.2.5.4.5h2.1a.5.5,0,0,0,.5-.5V7.219A.5.5,0,0,0,13.84,6.852ZM4.5,4h6l1,2h-8ZM5,8.6c0,.2-.3.4-.5.4H2.4C2.2,9,2,8.7,2,8.5V7.4c.1-.3.3-.5.6-.4l2,.4c.2,0,.4.3.4.5Zm8-.1c0,.2-.2.5-.4.5H10.5c-.2,0-.5-.2-.5-.4V7.9c0-.2.2-.5.4-.5l2-.4c.3-.1.5.1.6.4Z"/>
            <rect width="50%" height="45%" opacity={0} onClick={()=>{setSelectedCarId(car.id)}}/>
        </svg>
    )
}

export default SVGComponent;
