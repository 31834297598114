import * as React from "react";


function StopSign() {

    return (
        <svg
            viewBox="0 0 512 512"
            xmlns="http://www.w3.org/2000/svg">
            <g>
      <g>
        <g>
          <polygon
            style={{
              fill: "#E94B5C",
            }}
            points="512,149.916 512,362.083 362.084,512 149.916,512 0,362.083 0,149.916 149.916,0  362.084,0  "
          />
        </g>
        <g>
          <path
            style={{
              fill: "#FFFFFF",
            }}
            d="M347.775,34.539h-183.55L34.539,164.225v183.55L164.225,477.46h183.55l129.685-129.686v-183.55 L347.775,34.539z M463.399,341.936L341.936,463.398H170.064L48.602,341.936V170.064L170.064,48.602h171.873l121.462,121.462 V341.936z"
          />
        </g>
        <g>
          <path
            style={{
              fill: "#D94D64",
            }}
            d="M149.834,282.809c-0.247-3.372-0.905-6.415-1.892-9.293c-1.315-3.948-3.536-7.237-6.661-9.951 c-2.385-2.384-5.345-4.194-8.717-5.591c-3.372-1.316-7.648-2.385-12.911-3.043l-13.486-2.056 c-2.467-0.329-4.441-0.822-6.085-1.563c-1.645-0.74-3.043-1.562-4.276-2.631c-1.151-1.151-1.974-2.385-2.467-3.701 c-0.412-1.397-0.658-2.796-0.658-4.194c0-1.397,0.165-2.795,0.658-4.029c0.576-2.221,1.809-4.112,3.536-5.838 c2.796-2.714,7.154-4.112,13.075-4.112c3.783,0,7.648,0.412,11.842,1.316c4.112,0.904,7.895,2.96,11.431,6.25l13.98-13.487 c-1.48-1.397-2.96-2.632-4.523-3.783c-3.618-2.714-7.484-4.77-11.513-6.168c-5.839-1.891-12.664-2.879-20.559-2.879 c-6.168,0-11.677,0.823-16.53,2.467c-4.852,1.644-8.963,3.947-12.418,6.909c-3.371,2.959-5.921,6.414-7.73,10.525 c-1.316,2.96-2.138,6.168-2.467,9.54c-0.164,1.315-0.246,2.631-0.246,4.029c0,9.456,2.714,16.694,8.059,21.71 c2.549,2.384,5.592,4.358,9.128,5.839c3.454,1.481,7.73,2.631,12.829,3.372l13.487,1.974c2.878,0.412,5.016,0.905,6.415,1.397 c1.398,0.576,2.631,1.316,3.783,2.385c1.48,1.316,2.467,2.961,2.879,4.935c0.493,1.233,0.658,2.631,0.658,4.194 c0,4.44-1.727,7.812-5.099,10.197c-3.371,2.384-8.47,3.618-15.131,3.618c-5.181,0-10.115-0.659-14.803-2.056 c-4.605-1.316-8.799-3.783-12.335-7.237l-9.95,9.622l-4.359,4.276c5.51,5.509,11.596,9.292,18.339,11.43 c6.743,2.138,14.391,3.207,22.944,3.207c5.921,0,11.513-0.74,16.612-2.302c5.181-1.481,9.621-3.619,13.404-6.497 c3.7-2.797,6.661-6.332,8.799-10.444c2.138-4.194,3.207-8.882,3.207-14.309C150.08,285.44,149.998,284.124,149.834,282.809z"
          />
          <path
            style={{
              fill: "#D94D64",
            }}
            d="M162.416,208.961v19.243h29.605v91.199h22.121v-91.199h29.522v-19.243H162.416z"
          />
          <path
            style={{
              fill: "#D94D64",
            }}
            d="M341.361,260.112c0-3.7-0.082-7.073-0.165-10.197c-0.164-4.194-0.575-8.141-1.316-11.678 c-0.74-3.619-1.891-6.908-3.454-9.869c-1.563-3.042-3.701-5.921-6.579-8.717c-3.783-3.7-8.141-6.578-13.075-8.634 c-4.852-1.973-10.526-2.961-17.105-2.961c-6.579,0-12.335,0.988-17.269,2.961c-4.934,2.056-9.293,4.934-13.158,8.634 c-2.796,2.796-5.016,5.675-6.579,8.717c-1.563,2.961-2.632,6.25-3.289,9.869c-0.74,3.536-1.151,7.484-1.316,11.678 c-0.082,3.124-0.165,6.497-0.165,10.197c-0.082,1.315-0.082,2.714-0.082,4.112c0,5.262,0.082,10.033,0.246,14.227 c0.165,4.276,0.576,8.141,1.316,11.759c0.657,3.537,1.727,6.826,3.289,9.869c1.563,2.96,3.783,5.838,6.579,8.634 c3.865,3.784,8.224,6.579,13.158,8.635c4.934,2.056,10.691,3.043,17.269,3.043c6.579,0,12.253-0.987,17.105-3.043 c4.934-2.056,9.293-4.852,13.075-8.635c2.879-2.796,5.017-5.674,6.579-8.634c1.563-3.043,2.713-6.332,3.454-9.869 c0.74-3.618,1.151-7.483,1.316-11.759c0.165-4.194,0.247-8.965,0.247-14.227C341.443,262.825,341.443,261.427,341.361,260.112z  M280.013,260.112c0-3.536,0.082-6.497,0.165-9.047c0.165-3.453,0.411-6.331,0.823-8.552c0.493-2.303,0.987-4.112,1.727-5.51 c0.658-1.397,1.563-2.713,2.632-3.865c1.645-1.727,3.701-3.125,6.085-4.276c2.385-1.069,5.099-1.562,8.224-1.562 c3.043,0,5.757,0.493,8.141,1.562c2.385,1.151,4.359,2.55,6.004,4.276c1.069,1.151,1.891,2.467,2.632,3.865 c0.658,1.398,1.233,3.207,1.644,5.51c0.412,2.221,0.74,5.099,0.905,8.552c0.164,2.55,0.246,5.51,0.246,9.047 c0.082,1.315,0.082,2.714,0.082,4.112c0,5.262-0.082,9.621-0.329,13.075c-0.165,3.454-0.493,6.332-0.905,8.553 c-0.411,2.22-0.987,4.03-1.644,5.428c-0.74,1.397-1.563,2.631-2.632,3.783c-1.645,1.809-3.618,3.206-6.004,4.359 c-2.385,1.15-5.099,1.727-8.141,1.727c-3.125,0-5.838-0.576-8.224-1.727c-2.385-1.152-4.441-2.55-6.085-4.359 c-1.069-1.151-1.974-2.385-2.632-3.783c-0.74-1.398-1.233-3.208-1.727-5.428c-0.411-2.22-0.658-5.099-0.823-8.553 c-0.165-3.454-0.247-7.813-0.247-13.075C279.931,262.825,279.931,261.427,280.013,260.112z"
          />
          <path
            style={{
              fill: "#D94D64",
            }}
            d="M444.978,239.306c-0.329-3.207-1.069-6.25-2.302-9.128c-1.645-4.194-4.112-7.895-7.319-11.019 c-3.125-3.207-7.072-5.675-11.76-7.484c-4.605-1.81-9.868-2.714-15.707-2.714h-43.832v110.442h22.039v-41.53h21.793 c5.838,0,11.102-0.903,15.707-2.713c4.688-1.809,8.635-4.275,11.76-7.484c3.207-3.124,5.674-6.825,7.319-11.019 c1.727-4.194,2.549-8.634,2.549-13.24C445.225,242.02,445.142,240.622,444.978,239.306z M386.097,228.204h20.641 c5.099,0,9.128,1.398,12.007,4.194c1.974,1.891,3.371,4.193,3.947,6.908c0.329,1.316,0.493,2.632,0.493,4.112 c0,4.523-1.48,8.224-4.441,10.938c-2.879,2.713-6.908,4.112-12.007,4.112h-20.641V228.204z"
          />
        </g>
        <g>
          <path
            style={{
              fill: "#FFFFFF",
            }}
            d="M147.942,265.293c-1.315-3.948-3.536-7.237-6.661-9.951c-2.385-2.303-5.345-4.194-8.717-5.509 c-3.372-1.398-7.648-2.385-12.911-3.125l-13.486-2.056c-2.467-0.329-4.441-0.822-6.085-1.563 c-1.645-0.658-3.043-1.562-4.276-2.631c-1.151-1.151-1.974-2.385-2.467-3.701c-0.412-1.315-0.658-2.714-0.658-4.194 c0-3.783,1.398-7.071,4.194-9.868c2.796-2.713,7.154-4.112,13.075-4.112c3.783,0,7.648,0.494,11.842,1.316 c4.112,0.904,7.895,2.96,11.431,6.332l9.457-9.128l4.523-4.359c-4.852-4.688-10.28-7.977-16.036-9.95 c-5.839-1.974-12.664-2.961-20.559-2.961c-6.168,0-11.677,0.823-16.53,2.467c-4.852,1.644-8.963,3.947-12.418,6.908 c-3.371,2.959-5.921,6.496-7.73,10.525c-1.809,4.112-2.714,8.634-2.714,13.651c0,1.397,0.082,2.796,0.246,4.112 c0.658,7.4,3.29,13.24,7.813,17.598c2.549,2.385,5.592,4.276,9.128,5.757c3.454,1.563,7.73,2.631,12.829,3.372l13.487,2.056 c2.878,0.412,5.016,0.822,6.415,1.397c1.398,0.494,2.631,1.234,3.783,2.303c2.385,2.303,3.536,5.346,3.536,9.129 c0,1.48-0.164,2.795-0.658,4.03c-0.658,2.549-2.138,4.605-4.441,6.25c-3.371,2.384-8.47,3.535-15.131,3.535 c-5.181,0-10.115-0.659-14.803-1.974c-4.605-1.398-8.799-3.783-12.335-7.319l-14.309,13.981c1.398,1.397,2.879,2.631,4.359,3.865 c4.276,3.453,8.963,5.921,13.98,7.483c6.743,2.138,14.391,3.207,22.944,3.207c5.921,0,11.513-0.74,16.612-2.219 c5.181-1.563,9.621-3.701,13.404-6.579c3.7-2.797,6.661-6.332,8.799-10.444c1.563-3.043,2.55-6.415,2.961-10.115 c0.164-1.316,0.246-2.714,0.246-4.194C150.08,273.68,149.34,269.24,147.942,265.293z"
          />
          <path
            style={{
              fill: "#FFFFFF",
            }}
            d="M162.416,200.738v19.243h29.605v91.281h22.121v-91.281h29.522v-19.243H162.416z"
          />
          <path
            style={{
              fill: "#FFFFFF",
            }}
            d="M341.196,241.691c-0.164-4.194-0.575-8.141-1.316-11.678c-0.74-3.537-1.891-6.825-3.454-9.869 c-1.563-2.959-3.701-5.921-6.579-8.717c-3.783-3.7-8.141-6.578-13.075-8.552c-4.852-2.056-10.526-3.044-17.105-3.044 c-6.579,0-12.335,0.988-17.269,3.044c-4.934,1.974-9.293,4.852-13.158,8.552c-2.796,2.796-5.016,5.757-6.579,8.717 c-1.563,3.044-2.632,6.332-3.289,9.869c-0.74,3.537-1.151,7.484-1.316,11.678c-0.165,4.276-0.246,9.046-0.246,14.309 c0,1.397,0,2.796,0.082,4.112c0,3.7,0.082,7.072,0.165,10.197c0.165,4.194,0.576,8.141,1.316,11.677 c0.657,3.537,1.727,6.826,3.289,9.869c1.563,2.96,3.783,5.921,6.579,8.716c3.865,3.701,8.224,6.579,13.158,8.553 c4.934,2.056,10.691,3.043,17.269,3.043c6.579,0,12.253-0.987,17.105-3.043c4.934-1.974,9.293-4.852,13.075-8.553 c2.879-2.796,5.017-5.756,6.579-8.716c1.563-3.043,2.713-6.332,3.454-9.869c0.74-3.535,1.151-7.483,1.316-11.677 c0.082-3.125,0.165-6.497,0.165-10.197c0.082-1.316,0.082-2.714,0.082-4.112C341.443,250.737,341.361,245.967,341.196,241.691z  M319.239,260.112c0,3.453-0.082,6.415-0.246,8.964c-0.165,3.536-0.493,6.332-0.905,8.553c-0.411,2.22-0.987,4.03-1.644,5.428 c-0.74,1.397-1.563,2.714-2.632,3.783c-1.645,1.809-3.618,3.289-6.004,4.359c-2.385,1.15-5.099,1.727-8.141,1.727 c-3.125,0-5.838-0.576-8.224-1.727c-2.385-1.07-4.441-2.55-6.085-4.359c-1.069-1.069-1.974-2.385-2.632-3.783 c-0.74-1.398-1.233-3.208-1.727-5.428c-0.411-2.22-0.658-5.016-0.823-8.553c-0.082-2.549-0.165-5.51-0.165-8.964 c-0.082-1.316-0.082-2.714-0.082-4.112c0-5.263,0.082-9.622,0.247-13.076c0.165-3.536,0.411-6.332,0.823-8.634 c0.493-2.303,0.987-4.112,1.727-5.51c0.658-1.397,1.563-2.713,2.632-3.783c1.645-1.809,3.701-3.207,6.085-4.276 c2.385-1.069,5.099-1.644,8.224-1.644c3.043,0,5.757,0.575,8.141,1.644c2.385,1.069,4.359,2.467,6.004,4.276 c1.069,1.069,1.891,2.385,2.632,3.783c0.658,1.398,1.233,3.207,1.644,5.51c0.412,2.302,0.74,5.099,0.905,8.634 c0.246,3.454,0.329,7.813,0.329,13.076C319.322,257.397,319.322,258.796,319.239,260.112z"
          />
          <path
            style={{
              fill: "#FFFFFF",
            }}
            d="M442.675,221.954c-1.645-4.194-4.112-7.895-7.319-11.019c-3.125-3.207-7.072-5.675-11.76-7.484 c-4.605-1.81-9.868-2.713-15.707-2.713h-43.832v110.524h22.039V269.65h21.793c5.838,0,11.102-0.904,15.707-2.713 c4.688-1.809,8.635-4.276,11.76-7.484c3.207-3.124,5.674-6.825,7.319-11.019c1.234-2.878,1.974-5.921,2.302-9.128 c0.165-1.315,0.247-2.714,0.247-4.112C445.225,230.588,444.402,226.148,442.675,221.954z M422.692,239.306 c-0.576,2.714-1.974,5.016-3.947,6.826c-2.879,2.795-6.908,4.112-12.007,4.112h-20.641v-30.263h20.641 c5.099,0,9.128,1.398,12.007,4.194c2.96,2.796,4.441,6.496,4.441,11.019C423.185,236.674,423.021,238.072,422.692,239.306z"
          />
        </g>
      </g>
      <polygon
        style={{
          opacity: 0.1,
          fill: "#FFFFFF",
        }}
        points="149.916,0 0,149.916 0,362.083 149.916,512 256,512 256,0  "
      />
    </g>
        </svg>
    );
}

export default StopSign;
