import React from "react"

interface article{
    img : string,
    title : string,

    link : string,

    hashtags : string[]

}


export function Article(props: article){

    return (
        <div className="max-w-sm rounded-xl overflow-hidden shadow-lg">
            <a href={props.link} target="_blank"  rel="noreferrer">
                <img className="w-full" src={props.img} alt="Sunset in the mountains"/>
            </a>
                <div className="px-6 py-4">
                    <div className="font-bold text-xl mb-2">{props.title}</div>
                </div>
                <div className="px-6 pt-4 pb-2">
                    {
                        props.hashtags.map((hashtag:String) => {
                            return(
                                <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#{hashtag}</span>
                            )
                        })}
                </div>

        </div>
    )
}
