import * as React from "react";

function SelectedPattern() {

    return (
        <svg
            viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg"
            fill="red">

            <path
                style={{
                    stroke: "red",
                    strokeWidth: 2,
                }}
                d="M0 0h25m50 0h25M0 100h25m50 0h25M0 0v25m0 50v25M100 0v25m0 50v25"
            />
        </svg>
    );
}

export default SelectedPattern;
