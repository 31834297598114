export default function TrafficLight(props:{state:string}) {
    return (
        <svg viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg">

            {/* bg */}
            <path
                d="M4 83.05v-38.1c0-5.26 4.27-9.53 9.53-9.53h100.94c5.26 0 9.53 4.27 9.53 9.53v38.11c0 5.26-4.27 9.53-9.53 9.53H13.53C8.27 92.58 4 88.32 4 83.05z"
                fill="#424242"/>

            {/* green */}
            <circle cx="27.29" cy="65.26" r="13.41" fill="#24f22b"/>
            {!(props.state === "green") && <circle cx="27.29" cy="65.26" r="13.41" fill="black" opacity="0.5"/>}

            {/* yellow */}
            <circle cx="64" cy="65.26" r="13.41" fill="#ffe62e"/>
            {!(props.state === "yellow") && <circle cx="64" cy="65.26" r="13.41" fill="black" opacity="0.5"/>}

            {/* red */}
            <circle cx="100.71" cy="65.26" r="13.41" fill="#ff4545"/>
            {!(props.state === "red") && <circle cx="100.71" cy="65.26" r="13.41" fill="black" opacity="0.40"/>}

            {/* eyebrows */}
            <path
                d="M12.77 58.77c-.47.92-1.89.88-1.76-.52c.32-3.41 2.26-6.09 3.85-7.73c3.16-3.32 7.8-5.21 12.43-5.2c4.63-.02 9.27 1.88 12.44 5.19c1.59 1.64 3.85 5.3 3.86 7.85c0 1.11-1.26 1.41-1.72.51c-1.15-2.24-4.71-9.55-14.57-9.55s-13.39 7.23-14.53 9.45z"
                fill="#757575"/>
            <path
                d="M49.48 58.77c-.47.92-1.89.88-1.76-.52c.32-3.41 2.26-6.09 3.85-7.73c3.16-3.32 7.8-5.21 12.43-5.2c4.63-.02 9.27 1.88 12.44 5.19c1.59 1.64 3.85 5.3 3.86 7.85c0 1.11-1.26 1.41-1.72.51c-1.15-2.24-4.71-9.55-14.57-9.55s-13.39 7.23-14.53 9.45z"
                fill="#757575"/>
            <path
                d="M86.18 58.77c-.47.92-1.89.88-1.76-.52c.32-3.41 2.26-6.09 3.85-7.73c3.16-3.32 7.8-5.21 12.43-5.2c4.63-.02 9.27 1.88 12.44 5.19c1.59 1.64 3.85 5.3 3.86 7.85c0 1.11-1.26 1.41-1.72.51c-1.15-2.24-4.71-9.55-14.57-9.55s-13.38 7.23-14.53 9.45z"
                fill="#757575"/>

        </svg>
    )
}
