import * as React from "react";

function BuildingSvg() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 502 502"
            xmlSpace="preserve"
            width={80}
            height={80}
        >
            <g>
                <rect
                    x={133.475}
                    y={124.053}
                    style={{
                        fill: "#CCD7EB",
                    }}
                    width={235.049}
                    height={367.947}
                />
                <polygon
                    style={{
                        fill: "#4D93E8",
                    }}
                    points="394.311,124.053 107.689,124.053 147.36,52.646 355.632,52.646  "
                />
                <rect
                    x={300.093}
                    y={10}
                    style={{
                        fill: "#CCD7EB",
                    }}
                    width={26.778}
                    height={42.646}
                />
                <rect
                    x={246.537}
                    y={10}
                    style={{
                        fill: "#CCD7EB",
                    }}
                    width={26.778}
                    height={42.646}
                />
                <rect
                    x={165.212}
                    y={159.757}
                    style={{
                        fill: "#4D93E8",
                    }}
                    width={70.321}
                    height={36.695}
                />
                <rect
                    x={267.459}
                    y={159.757}
                    style={{
                        fill: "#4D93E8",
                    }}
                    width={70.321}
                    height={36.695}
                />
                <rect
                    x={165.212}
                    y={231.165}
                    style={{
                        fill: "#4D93E8",
                    }}
                    width={70.321}
                    height={36.695}
                />
                <rect
                    x={267.459}
                    y={231.165}
                    style={{
                        fill: "#4D93E8",
                    }}
                    width={70.321}
                    height={36.695}
                />
                <rect
                    x={165.212}
                    y={300.588}
                    style={{
                        fill: "#4D93E8",
                    }}
                    width={70.321}
                    height={36.695}
                />
                <rect
                    x={267.459}
                    y={300.588}
                    style={{
                        fill: "#4D93E8",
                    }}
                    width={70.321}
                    height={36.695}
                />
                <rect
                    x={165.212}
                    y={371.996}
                    style={{
                        fill: "#4D93E8",
                    }}
                    width={70.321}
                    height={36.695}
                />
                <rect
                    x={267.459}
                    y={371.996}
                    style={{
                        fill: "#4D93E8",
                    }}
                    width={70.321}
                    height={36.695}
                />
                <rect
                    x={223.726}
                    y={441.42}
                    style={{
                        fill: "#4D93E8",
                    }}
                    width={54.547}
                    height={50.58}
                />
                <g>
                    <path d="M403.104,119.291l-38.679-71.408c-1.748-3.227-5.123-5.237-8.793-5.237h-18.761V10c0-5.523-4.477-10-10-10h-26.778    c-5.523,0-10,4.477-10,10v32.646h-6.778V10c0-5.523-4.477-10-10-10h-26.778c-5.523,0-10,4.477-10,10v32.646H147.36    c-3.632,0-6.978,1.969-8.742,5.144l-39.67,71.408c-1.721,3.097-1.674,6.874,0.123,9.928s5.076,4.929,8.619,4.929h15.786V492    c0,5.523,4.477,10,10,10h235.05c5.523,0,10-4.477,10-10V134.054h15.786c3.524,0,6.788-1.855,8.591-4.883    C404.706,126.143,404.782,122.39,403.104,119.291z M310.093,20h6.778v22.646h-6.778V20z M256.537,20h6.778v22.646h-6.778V20z     M153.244,62.646h196.432l27.845,51.408H124.685L153.244,62.646z M268.273,482h-34.547v-30.58h34.547V482z M358.525,482h-70.251    v-40.58c0-5.523-4.477-10-10-10h-54.547c-5.523,0-10,4.477-10,10V482h-70.251V134.054h215.05V482z" />
                    <path d="M165.212,206.453h70.321c5.523,0,10-4.477,10-10v-36.695c0-5.523-4.477-10-10-10h-70.321c-5.523,0-10,4.477-10,10v36.695    C155.212,201.976,159.689,206.453,165.212,206.453z M175.212,169.757h50.321v16.695h-50.321V169.757z" />
                    <path d="M267.459,206.453h70.321c5.523,0,10-4.477,10-10v-36.695c0-5.523-4.477-10-10-10h-70.321c-5.523,0-10,4.477-10,10v36.695    C257.459,201.976,261.936,206.453,267.459,206.453z M277.459,169.757h50.321v16.695h-50.321V169.757z" />
                    <path d="M165.212,277.86h70.321c5.523,0,10-4.477,10-10v-36.695c0-5.523-4.477-10-10-10h-70.321c-5.523,0-10,4.477-10,10v36.695    C155.212,273.383,159.689,277.86,165.212,277.86z M175.212,241.165h50.321v16.695h-50.321V241.165z" />
                    <path d="M267.459,277.86h70.321c5.523,0,10-4.477,10-10v-36.695c0-5.523-4.477-10-10-10h-70.321c-5.523,0-10,4.477-10,10v36.695    C257.459,273.383,261.936,277.86,267.459,277.86z M277.459,241.165h50.321v16.695h-50.321V241.165z" />
                    <path d="M165.212,347.284h70.321c5.523,0,10-4.477,10-10v-36.696c0-5.523-4.477-10-10-10h-70.321c-5.523,0-10,4.477-10,10v36.696    C155.212,342.807,159.689,347.284,165.212,347.284z M175.212,310.588h50.321v16.696h-50.321V310.588z" />
                    <path d="M267.459,347.284h70.321c5.523,0,10-4.477,10-10v-36.696c0-5.523-4.477-10-10-10h-70.321c-5.523,0-10,4.477-10,10v36.696    C257.459,342.807,261.936,347.284,267.459,347.284z M277.459,310.588h50.321v16.696h-50.321V310.588z" />
                    <path d="M165.212,418.691h70.321c5.523,0,10-4.477,10-10v-36.695c0-5.523-4.477-10-10-10h-70.321c-5.523,0-10,4.477-10,10v36.695    C155.212,414.214,159.689,418.691,165.212,418.691z M175.212,381.996h50.321v16.695h-50.321V381.996z" />
                    <path d="M267.459,418.691h70.321c5.523,0,10-4.477,10-10v-36.695c0-5.523-4.477-10-10-10h-70.321c-5.523,0-10,4.477-10,10v36.695    C257.459,414.214,261.936,418.691,267.459,418.691z M277.459,381.996h50.321v16.695h-50.321V381.996z" />
                    <path d="M306.043,84.3H293.15c-5.523,0-10,4.477-10,10s4.477,10,10,10h12.893c5.523,0,10-4.477,10-10S311.566,84.3,306.043,84.3z" />
                    <path d="M258.439,84.3H164.22c-5.523,0-10,4.477-10,10s4.477,10,10,10h94.218c5.523,0,10-4.477,10-10S263.961,84.3,258.439,84.3z" />
                </g>
            </g>
        </svg>
    );
}
export default BuildingSvg;
