import * as React from "react";


function Water() {


    return (
        <svg

            viewBox="0 0 1000 1000"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">

               <rect width="1000" height="1000" fill="#0f5e9c"/>

        </svg>
    );
}

export default Water;
