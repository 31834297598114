import * as React from "react";


function TurnRoad2() {


    return (
        <svg

            viewBox="0 0 1000 1000"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">

                <path d="M-0.000732422 1001.1L-0.000732422 1.10034L999.999 1.10034L999.999 1001.1L-0.000732422 1001.1Z" fill="#808080"/>
                <path d="M43.9318 947.204L43.9318 2.2041H73.9318L73.9318 947.204H43.9318Z" fill="#F5F5F5"/>
                <path d="M499.374 501.064V1.06372H529.374L529.374 501.064H499.374Z" fill="#E5F43B"/>
                <path d="M1000.04 530.475H500.036V500.475H1000.04V530.475Z" fill="#E5F43B"/>
                <path d="M1001.1 956.044L45.1003 956.044L45.1003 926.044H1001.1V956.044Z" fill="#F5F5F5"/>
                <path d="M998.894 73.9443L923.894 73.9443L923.894 43.9443L998.894 43.9443L998.894 73.9443Z" fill="#F5F5F5"/>
                <path d="M923.839 74.0007L923.839 0.000732422L953.839 0.000732422L953.839 74.0007L923.839 74.0007Z" fill="#F5F5F5"/>

        </svg>
    );
}

export default TurnRoad2;
