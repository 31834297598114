import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {ITile} from "../Types/Tile";
import {getCityState, pingPong, subscribe_listener, updateDoc, poke} from "../ws/ws";
import Placeholder from "../Tiles/Placeholder";
import Empty from "../Tiles/Empty";
import {resizeTiles} from "../Types/TileProps";
import Modal from "../comps/MapConfigModal";
import Selected from "../Tiles/Selected";
import {fetchTile, includesArray} from "../utils";
import SelectedPattern from "../Tiles/SelectedPattern";
import {useTranslation} from 'react-i18next';
import {t} from "i18next";
import { BridgeContext, TrafficLightsContext } from "../App";
import RenderSigns from "../utils/RenderSings";
import {ISigns} from "../Types/ISigns";
import {IIncidents} from "../Types/Incidents";


function MapConfig() {

    const [flagConnection, setFlagConnection] = useState<boolean>(false)

    const [bridgeState, setBridgeState] = useState<string>("")
    const [mode, setMode] = useState<string>("")

    const roads = [1, 2, 3, 4, 19, 20]
    const turns = [5, 6, 7, 8]
    const intersections = [9, 10, 11]
    const fillers = [12, 13]
    const signs = [14, 15, 16, 17, 18]

    const navigate = useNavigate();
    const { i18n } = useTranslation();

    const [selectedTile, setSelectedTile] = useState<ITile>()
    const [selectedTilePattern, setSelectedTilePattern] = useState<ITile>()

    const [isAllChangeSaved, setIsAllChangeSaved] = useState(true)
    const [open, setOpen] = useState(false)


    const [editSigns, setEditSigns] = useState(false)

    const [map, setMap] = useState<Array<ITile>>([])
    const [emptyMap, setEmptyMap] = useState<Array<ITile>>([])
    const [tileSize, setTileSize] = useState<number>(0)
    const [incidents, setIncidents] = useState<Array<IIncidents>>([])
    const [filter, setFilter] = useState<string>('All');

    const [trafficLight, setTrafficLight] = useState<object>({"": {E: "green", N: "red", S: "red", W: "green"}})
    const [addTrafficLightIntent, setAddTrafficLightIntent] = useState<Array<string>>([])
    const [delTrafficLightIntent, setDelTrafficLightIntent] = useState<Array<string>>([])
    const [signBackup, setSignBackup] = useState<number>(-1)


    // handle page load logic
    useEffect(() => {
        pingPong(setFlagConnection, setBridgeState, setMode, setMap, setTrafficLight, setIncidents, null);
        try{poke()}catch(e){}
    }, []);

    useEffect(() => {
        if(flagConnection){
            getCityState()
                .then((data) => {
                    if(data.map) {
                        setMap(data.map);
                    } else {
                        // If map is empty, generate empty tiles
                        setEmptyTiles();
                    }
                    if(data.trafficLights) {
                        setTrafficLight(data.trafficLights)
                    }
                    if(data.pont) {
                        setBridgeState(data.pont)
                    }
                    if(data.incidents){
                        setIncidents(data.incidents)
                    }
                    subscribe_listener();
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }, [flagConnection])

    // handle text direction based on selected language
    useEffect(() => {
        if (i18n.language === 'ar') {
            document.body.dir = 'rtl';
        } else {
            document.body.dir = 'ltr';
        }
    }, [i18n.language]);

    // keep list of addable tiles up to date and adjust map size to fit container
    useEffect(() => {
        setEmptyTiles()
        setTileSize(resizeTiles(map, 2))
    }, [map])

    // handle value change in dropdown filter in tile selection panel
    const handleFilterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setFilter(event.target.value);
    };

    // creates an array of tiles bordering every occupied ones
    // this newly created array includes all valid positions to add a new tile
    function setEmptyTiles() {
        let filledTiles: Array<Array<number>> = []
        let emptyTiles: Array<Array<number>> = []

        map.map((t) => {
            filledTiles.push([t.x, t.y])
        })

        // removing duplicate and overlapping empty tiles
        // this would have been so much easier if Array.includes wasn't only doing shallow checks
        filledTiles.forEach((f) => {
            if (!includesArray(emptyTiles, [f[0] - 1, f[1]]) && !includesArray(filledTiles, [f[0] - 1, f[1]])) emptyTiles.push([f[0] - 1, f[1]])
            if (!includesArray(emptyTiles, [f[0] + 1, f[1]]) && !includesArray(filledTiles, [f[0] + 1, f[1]])) emptyTiles.push([f[0] + 1, f[1]])
            if (!includesArray(emptyTiles, [f[0], f[1] - 1]) && !includesArray(filledTiles, [f[0], f[1] - 1])) emptyTiles.push([f[0], f[1] - 1])
            if (!includesArray(emptyTiles, [f[0], f[1] + 1]) && !includesArray(filledTiles, [f[0], f[1] + 1])) emptyTiles.push([f[0], f[1] + 1])
        })

        const newEmptyMap: Array<ITile> = []
        emptyTiles.forEach((t) => {
            newEmptyMap.push({
                id: newEmptyMap.length,
                x: t[0],
                y: t[1],
                tile: 0
            })
        })
        setEmptyMap(newEmptyMap)
    }

    // mark or unmark tile as selected on map
    function onFilledTileSelected(x: number, y: number) {
        const selected = map.filter((t) => {
            return t.x === x && t.y === y
        })[0]

        if (selectedTile && selectedTile.x === selected.x && selectedTile.y === selected.y) {
            // when selecting an already selected tile, clear selected status
            setSelectedTile(undefined)
        } else {
            // otherwise, select new tile
            setSelectedTile(selected)
        }

    }

    // add new tile to current map
    // shift map to keep top left corner on (0, 0) if needed
    function onEmptyTileSelected(x: number, y: number) {
        if (editSigns) {
            console.log("Cannot add a new tile while editing tile configuration");
            return; // Exit the function if no tile is selected
        }
        if (!selectedTilePattern) {
            console.log("No tile is selected.");
            return; // Exit the function if no tile is selected
        }
        if (selectedTilePattern && signs.includes(selectedTilePattern.tile)) {
            console.log("Cannot add sign as tile")
            return;
        }

        setIsAllChangeSaved(false);

        // find all occupied ids
        let occupiedIds: Array<number> = []
        map.forEach((tile) => {
            occupiedIds.push(tile.id)
        })

        // find first unoccupied id
        let firstIdAvailable = 0;
        while (occupiedIds.includes(firstIdAvailable)) {
            firstIdAvailable++
        }

        let shiftedMap: Array<ITile> = map

        // shift map right if no space on left
        if (x < 0) {
            shiftedMap = map.map(t => {
                return {
                    ...t,
                    x: t.x + 1,
                };
            });
        }

        // shift map down if no space up
        if (y < 0) {
            shiftedMap = map.map(t => {
                return {
                    ...t,
                    y: t.y + 1,
                };
            });
        }

        // add new tile to map with first unoccupied id
        const newTile = {
            id: firstIdAvailable,
            x: x < 0 ? x + 1 : x,
            y: y < 0 ? y + 1 : y,
            tile: selectedTilePattern.tile
        }

        setMap(shiftedMap.concat(newTile))

        // Update the selected tile state to reflect the newly added tile
        setSelectedTile(newTile);
    }

    // remove selected tile from locally stored map
    function deleteTile() {
        if (selectedTile && map.length > 1) {
            setIsAllChangeSaved(false)
            let newMap = map

            // add intent to remove traffic light
            const intent = `${selectedTile.x}:${selectedTile.y}`
            if(!delTrafficLightIntent.includes(intent) && Object.hasOwn(trafficLight, intent)) {
                setDelTrafficLightIntent(delTrafficLightIntent.concat(intent))
            }

            // remove 1 tile
            newMap.splice(newMap.indexOf(selectedTile), 1)

            // shift map to keep top left corner as (0, 0)
            if (selectedTile.x === 0 || selectedTile.y === 0) {
                const countX = newMap.reduce((acc, current) => current.x === 0 ? acc + 1 : acc, 0)
                const countY = newMap.reduce((acc, current) => current.y === 0 ? acc + 1 : acc, 0)

                if (countX <= 0 || countY <= 0) {
                    newMap = map.map(t => {
                        return {
                            ...t,
                            x: countX <= 0 ? t.x - 1 : t.x,
                            y: countY <= 0 ? t.y - 1 : t.y,
                        };
                    });
                }
            }

            // apply changes to locally stored map
            setSelectedTile(undefined)
            setMap(newMap)
            setEmptyTiles()
        }
    }

    // save changes by overwriting remote data with local one
    function saveMap() {
        let tl = trafficLight

        // apply list of delete intent to local list of traffic lights
        delTrafficLightIntent.forEach((light) => {
            delete tl[light as keyof typeof tl]
        })

        // apply list of add intent to local list of traffic lights
        addTrafficLightIntent.forEach((light) => {
            tl = {...tl, [light]:{E: "green", N: "red", S: "red", W: "green"}}
        })

        // once applied, clear intent lists
        setDelTrafficLightIntent([])
        setAddTrafficLightIntent([])

        // overwrite map in Aliot and overwrite list of traffic lights in Aliot
        updateDoc({"/doc/map": map, "/doc/trafficLights": tl})
        setIsAllChangeSaved(true)
    }

    // attempt redirection to main page
    function returnHome() {
        if (isAllChangeSaved) {
            // all changes are saved, user can leave this page
            navigate("/main")
        } else {
            // display pop-up to warn of loss of unsaved changes, redirection left to pop-up
            setOpen(true)
        }
    }

    // display all tiles matching dropdown filter in the tile selection panel
    function renderUniqueTiles() {
        const uniqueTiles: ITile[] = [];
        const tileTypes: Set<number> = new Set();

        // Add tiles based on the selected filter
        const nbTiles = roads.length + turns.length + intersections.length + fillers.length + signs.length
        switch (filter) {
            case 'All':
                for (let i = 1; i <= nbTiles; i++) {
                    if(!signs.includes(i)) {
                        tileTypes.add(i);
                    }
                }
                break;
            case 'Roads':
                roads.forEach((type: number) => {
                    tileTypes.add(type);
                });
                break;
            case 'Turns':
                turns.forEach((type: number) => {
                    tileTypes.add(type);
                });
                break;
            case 'Intersection':
                intersections.forEach((type: number) => {
                    tileTypes.add(type);
                });
                break;
            case 'Signs':
                signs.forEach((type: number) => {
                    tileTypes.add(type);
                });
                break;
            case 'Fillers':
                fillers.forEach((type: number) => {
                    tileTypes.add(type);
                });
                break
            default:
                break;
        }

        // Add tiles to the uniqueTiles array based on the selected filter
        tileTypes.forEach((type: number) => {
            uniqueTiles.push({id: type, x: 0, y: 0, tile: type});
        });

        return uniqueTiles.map((tile: ITile) => (
            <div key={`${tile.tile}`} className="w-1/4 p-2">
                {getTitle(tile)}
                <div onClick={() => setSelectedTilePattern(tile)}
                     className={selectedTilePattern && selectedTilePattern.id === tile.id ? "selected-tile" : ""}>
                    {renderTile(tile.tile)}
                </div>
            </div>
        ));
    }

    // return category name of a tile base on its type
    // @params
    //      tile: a tile to get the category of
    // @returns
    //      an HTML <h2> element containing the category name of a tile
    function getTitle(tile: ITile) {
        if (roads.includes(tile.tile)) return <h2>{t('types.straight_road')}</h2>;
        else if (turns.includes(tile.tile)) return <h2>{t('types.turns')}</h2>;
        else if (intersections.includes(tile.tile)) return <h2>{t('types.intersections')}</h2>;
        else if (fillers.includes(tile.tile)) return <h2>{t('types.others')}</h2>;
        else if (signs.includes(tile.tile)) return <h2>{t('types.signs')}</h2>;
        else return null;

    }

    // render a tile in the tile selection panel
    // applies selected effect when needed
    // @params
    //      tile: type of tile to be rendered
    function renderTile(tile: number) {
        const mask = tile === selectedTilePattern?.tile ? <SelectedPattern></SelectedPattern> : <></>
        let base;
        base = fetchTile(tile)
        return <svg viewBox={"0 0 1000 1000"}>{base}{mask}</svg>
    }

    // toggle visibility of sign in a given direction ot the tile currently being edited
    // @params
    //      direction: desired direction to toggle visibility of a sign
    function toggleSign(direction: string) {
        if (selectedTile) {
            // tile already has signs
            if (selectedTile.signs) {
                if (selectedTilePattern) {
                    // toggle display of sign in selected direction
                    setSelectedTile({
                        ...selectedTile,
                        signs: {
                            ...selectedTile.signs,
                            [direction]: !selectedTile.signs[direction as keyof ISigns]
                        }
                    })
                } else console.log("no tile pattern selected")
            }
            // tile doesn't have any sign
            else {
                if (selectedTilePattern) {
                    // add sign prop to tile and display sign in selected direction
                    setSelectedTile({
                        ...selectedTile,
                        signs: {
                            "type": selectedTilePattern.tile,
                            "north": direction === "north",
                            "south": direction === "south",
                            "east": direction === "east",
                            "west": direction === "west",
                            "stateLightNS": "off",
                            "stateLightEW": "off"
                        }
                    })
                } else console.log("no tile pattern selected")
            }
        } else console.log("no tile selected")
    }

    // apply changes made to the sign layout of a tile
    // @params
    //      isRevert: controls whether changes come from a save or discard input
    function saveSigns(isRevert:boolean) {
        if (selectedTile) {
            setIsAllChangeSaved(false)
            let newMap = map

            const index = newMap.findIndex((tile) => tile.id === selectedTile.id)
            const intent = `${selectedTile.x}:${selectedTile.y}`

            if(!isRevert){
                // add intent to add traffic light if there wasn't one already
                if(!map[index].signs && selectedTile.signs?.type === 18 && !addTrafficLightIntent.includes(intent)) {
                    setAddTrafficLightIntent(addTrafficLightIntent.concat(intent))
                }
                newMap[index] = selectedTile
            } else {
                // discard changes and revert to previous state
                if(signBackup > 0 && selectedTile.signs){
                    newMap[index] = {...selectedTile, signs:{...selectedTile.signs, type:signBackup}}
                    changeSign(selectedTile, signBackup)
                } else {
                    newMap[index] = {...selectedTile, signs:undefined}
                }
            }

            // apply modification to local map
            setMap(newMap)
            setEditSigns(false)
        }
    }

    // change the type of sign to be displayed on a given tile
    // handles cases where a traffic light needs to be added to or removed from an intent list
    // @params
    //      tile: a given tile to apply modifications to
    //      newType: the id number of the type of sign to be applied to the tile
    function changeSign(tile:ITile, newType:number) {
        let newTile = tile
        if(newTile.signs) {

            // change sign type
            const oldType = newTile.signs.type
            newTile.signs.type = newType

            // handle traffic lights modification intents
            if(oldType !== newType) {
                const intent = `${tile.x}:${tile.y}`
                if(newType === 18) {
                    // adding intent w/o duplicates
                    if(!addTrafficLightIntent.includes(intent) && !Object.hasOwn(trafficLight, intent)) {
                        setAddTrafficLightIntent(addTrafficLightIntent.concat(intent))
                    }
                    // removing if present in mirror intent array
                    const index = delTrafficLightIntent.findIndex(e => e === intent)
                    if(index >= 0){
                        const temp = delTrafficLightIntent
                        temp.splice(index,1)
                        setDelTrafficLightIntent(temp)
                    }
                } else if (oldType === 18) {
                    // adding intent w/o duplicates
                    if(!delTrafficLightIntent.includes(intent) && Object.hasOwn(trafficLight, intent)) {
                        setDelTrafficLightIntent(delTrafficLightIntent.concat(intent))
                    }
                    // removing if present in mirror intent array
                    const index = addTrafficLightIntent.findIndex(e => e === intent)
                    if(index >= 0){
                        const temp = addTrafficLightIntent
                        temp.splice(index,1)
                        setAddTrafficLightIntent(temp)
                    }
                }
            }
        }
        // apply sign change
        setSelectedTile(newTile)
    }


    return (
        <BridgeContext.Provider value={bridgeState}>
            <TrafficLightsContext.Provider value={trafficLight}>
                <div className="grid grid-cols-10 h-full">

                    {/* Map Container */}
                    <div className="col-span-5 row-span-2 flex flex-col h-screen pb-16">
                        <div className="bg-blue-400 rounded-md m-2 p-2 h-full">
                            <div className="flex flex-col flex-1 h-full">

                                {/* Top Row */}
                                <div className=" flex justify-between items-start">

                                    {/* Delete Selected Tile button  */}
                                    <button
                                        className={"bg-red-400 hover:bg-red-500 font-bold py-2 px-4 rounded " +
                                            "disabled:opacity-70 disabled:bg-red-400  disabled:hover:bg-red-400"}
                                        disabled={selectedTile === undefined}
                                        onClick={deleteTile}>
                                        {t('buttons.delete')}
                                    </button>

                                    {/* Edit Tile button */}
                                    <button
                                        className={"bg-blue-300 hover:bg-blue-500 font-bold py-2 px-4 rounded " +
                                            "disabled:bg-blue-300 disabled:opacity-50 disabled:hover:bg-blue-300"}
                                        disabled={selectedTile === undefined}
                                        onClick={() => {
                                            if(selectedTile){
                                                setSignBackup(selectedTile.signs ? selectedTile.signs.type : -1)
                                            }
                                            if (selectedTile?.signs) {
                                                setSelectedTilePattern({id: 0, x: 0, y: 0, tile: selectedTile.signs.type})
                                            } else setSelectedTilePattern({id: 0, x: 0, y: 0, tile: 14})
                                            setEditSigns(true)
                                        }}>
                                        {t('buttons.edit')}
                                    </button>

                                </div>

                                {/* Main Map Display */}
                                <div className="flex justify-center items-start my-2 pt-2">
                                    <svg
                                        width={window.innerWidth * 0.7}
                                        height={window.innerHeight * 0.7}
                                        viewBox={`-${tileSize} -${tileSize} 5000 5000`}
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg">

                                        {/* if no map in alive code */}
                                        {map.length === 0 && (
                                            (() => {
                                                return <Empty tile={{id: 0, x: 0, y: 0, tile: 0}}
                                                              onSelected={onEmptyTileSelected} tileSize={tileSize}/>;
                                            })()
                                        )}

                                        {/* render filled tiles */}
                                        {map.map((t: ITile) => {
                                            return <Placeholder key={t.id} tile={t} onSelected={onFilledTileSelected}
                                                                tileSize={tileSize}></Placeholder>
                                        })}

                                        {/* render add tile buttons */}
                                        {emptyMap.map((t: ITile) => {
                                            return <Empty key={t.id} tile={t} onSelected={onEmptyTileSelected}
                                                          tileSize={tileSize}></Empty>
                                        })}

                                        {/* render marking on selected tile */}
                                        {selectedTile &&
                                            <Selected key={selectedTile.id} tile={selectedTile} onSelected={onEmptyTileSelected}
                                                      tileSize={tileSize}></Selected>
                                        }


                                    </svg>
                                </div>

                                {/* Bottom Row */}
                                <div className="grow flex justify-between items-end">
                                    <button className="bg-blue-300 hover:bg-blue-500 font-bold py-2 px-4 rounded"
                                            onClick={returnHome}>
                                        {t('buttons.back')}
                                    </button>
                                    <button
                                        className={"bg-blue-300 hover:bg-blue-500 font-bold py-2 px-4 rounded " +
                                            "disabled:bg-blue-300 disabled:opacity-50 disabled:hover:bg-blue-300"}
                                        disabled={isAllChangeSaved}
                                        onClick={saveMap}>
                                        {t('buttons.save')}
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>

                    {/* Display tiles with filter dropdown */}
                    <div className="col-span-5 row-span-2 flex flex-col h-screen pb-16">
                        <div className="bg-blue-400 rounded-md m-2 p-2 h-full overflow-clip flex flex-col ">

                            {/* Tile Selection Panel */}
                            {!editSigns &&
                                <>
                                    {/* Select dropdown for filtering */}
                                    <div className="flex justify-end mb-2">
                                        <select
                                            value={filter}
                                            onChange={handleFilterChange}
                                            className="px-2 py-1 border border-gray-400 rounded-md focus:outline-none focus:border-blue-500"
                                        >
                                            <option value="All">{t('types.all')}</option>
                                            <option value="Roads">{t('types.straight_road')}</option>
                                            <option value="Turns">{t('types.turns')}</option>
                                            <option value="Intersection">{t('types.intersections')}</option>
                                            <option value="Fillers">{t('types.others')}</option>
                                        </select>
                                    </div>

                                    {/* Render filtered tiles */}
                                    <div className="flex flex-wrap overflow-y-scroll">
                                        {renderUniqueTiles()}
                                    </div>
                                </>
                            }

                            {/* Tile Signs Modification Panel */}
                            {editSigns &&
                                <div className="h-full flex flex-col overflow-y-scroll">

                                    {/* Sign Selection Ron */}
                                    <div className="">
                                        <div className="text-2xl font-bold mx-3">{t('signalization')}</div>
                                        <div className="flex flex-row overflow-x-scroll">
                                            {signs.map((s) => {
                                                return <div
                                                    key={s}
                                                    className="w-1/6 m-3"
                                                    onClick={() => {
                                                        setSelectedTilePattern({id: 0, x: 0, y: 0, tile: s})
                                                        if(selectedTile){
                                                            changeSign(selectedTile, s)
                                                        }
                                                    }}>
                                                    {renderTile(s)}
                                                </div>
                                            })}
                                        </div>
                                    </div>

                                    <div>
                                        {/* User Prompt */}
                                        <div className="w-full flex justify-center">
                                            <div className="text-xl font-bold my-6 text-center w-2/3">
                                                {t('description_modif')}
                                            </div>
                                        </div>

                                        {/* Currently Modified Tile and toggle buttons */}
                                        <div className="flex flex-col justify-center items-center mt-4">

                                            {/* BOTTOM Toggle button */}
                                            <button
                                                className={"bg-blue-300 hover:bg-blue-500 font-bold py-2 px-4 w-11 rounded " +
                                                    "disabled:bg-blue-300 disabled:opacity-50 disabled:hover:bg-blue-300"}
                                                onClick={() => {
                                                    toggleSign('south')
                                                }}>
                                                {!selectedTile?.signs?.south && <>+</>}
                                                {selectedTile?.signs?.south && <>-</>}
                                            </button>

                                            {/* Middle Section (left btn, tile, right btn) */}
                                            <div className="flex flex-row justify-center items-center">
                                                {/* LEFT Toggle button */}
                                                <button
                                                    className={"bg-blue-300 hover:bg-blue-500 font-bold py-2 px-4 w-11 rounded " +
                                                        "disabled:bg-blue-300 disabled:opacity-50 disabled:hover:bg-blue-300"}
                                                    onClick={() => {
                                                        toggleSign('east')
                                                    }}>
                                                    {!selectedTile?.signs?.east && <>+</>}
                                                    {selectedTile?.signs?.east && <>-</>}
                                                </button>

                                                <svg className="m-6" viewBox={"0 0 1000 1000"} height={300}>
                                                    {selectedTile && renderTile(selectedTile.tile)}
                                                    {selectedTile && selectedTile.signs && RenderSigns(selectedTile)}
                                                </svg>

                                                {/* RIGHT Toggle button */}
                                                <button
                                                    className={"bg-blue-300 hover:bg-blue-500 font-bold py-2 px-4 w-11 rounded " +
                                                        "disabled:bg-blue-300 disabled:opacity-50 disabled:hover:bg-blue-300"}
                                                    onClick={() => {
                                                        toggleSign('west')
                                                    }}>
                                                    {!selectedTile?.signs?.west && <>+</>}
                                                    {selectedTile?.signs?.west && <>-</>}
                                                </button>
                                            </div>

                                            {/* TOP Toggle button */}
                                            <button
                                                className={"bg-blue-300 hover:bg-blue-500 font-bold py-2 px-4 w-11 rounded " +
                                                    "disabled:bg-blue-300 disabled:opacity-50 disabled:hover:bg-blue-300"}
                                                onClick={() => {
                                                    toggleSign('north')
                                                }}>
                                                {!selectedTile?.signs?.north && <>+</>}
                                                {selectedTile?.signs?.north && <>-</>}
                                            </button>
                                        </div>
                                    </div>

                                    {/* Bottom Row */}
                                    <div className="flex grow justify-between items-end">
                                        {/* Discard Changes button */}
                                        <button className="bg-blue-300 hover:bg-blue-500 font-bold py-2 px-4 rounded"
                                                onClick={() => {
                                                    saveSigns(true)
                                                    setEditSigns(false)
                                                }}>
                                            {t('buttons.discard_changes')}
                                        </button>
                                        {/* Save Changes button */}
                                        <button
                                            className={"bg-blue-300 hover:bg-blue-500 font-bold py-2 px-4 rounded "}
                                            onClick={() => {
                                                saveSigns(false)
                                            }}>
                                            {t('buttons.save_modif')}
                                        </button>
                                    </div>

                                </div>
                            }

                        </div>
                    </div>


                    {/* Confirmation pop-up */}
                    <Modal open={open} onClose={() => setOpen(false)}>
                        <div className="text-center">
                            <div className="mx-auto my-4">
                                <h3 className="text-lg font-black text-gray-800">{t('modal.title')}</h3>
                                <p className="text-sm text-gray-500 my-2">
                                    {t('modal.description')}
                                </p>
                                <p className="text-sm font-bold my-2">
                                    {t('modal.question')}
                                </p>
                            </div>
                            <div className="flex">
                                <div className="w-1/2 flex justify-start">
                                    <button className="bg-red-400 hover:bg-red-500 font-bold py-2 px-4 rounded w-fit"
                                            onClick={() => navigate("/main")}>
                                        {t('modal.agree')}
                                    </button>
                                </div>
                                <div className="w-1/2 flex justify-end">
                                    <button className="bg-blue-300 hover:bg-blue-500 font-bold py-2 px-4 rounded w-fit"
                                            onClick={() => setOpen(false)}>
                                        {t('modal.not_agree')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Modal>

                </div>
            </TrafficLightsContext.Provider>
        </BridgeContext.Provider>
    );
}

export default MapConfig;
