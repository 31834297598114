import * as React from "react";


function VerticalRoad() {


    return (
        <svg

            viewBox="0 0 1000 1000"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">

            <path d="M1000.08 1001.34L0.080281 1001.42L-0.00012207 1.41781L1000 1.3374L1000.08 1001.34Z" fill="#808080"/>
            <path d="M501.32 987.421L471.32 987.424L471.308 837.424L501.308 837.421L501.32 987.421Z" fill="#E5F43B"/>
            <path d="M500.657 771.423L470.657 771.425L470.645 621.425L500.645 621.423L500.657 771.423Z" fill="#E5F43B"/>
            <path d="M500.016 566.424L470.016 566.426L470.004 416.426L500.004 416.424L500.016 566.424Z" fill="#E5F43B"/>
            <path d="M499.352 354.425L469.352 354.427L469.34 204.427L499.34 204.425L499.352 354.425Z" fill="#E5F43B"/>
            <path d="M498.735 149.426L468.735 149.428L468.725 15.4279L498.725 15.4255L498.735 149.426Z" fill="#E5F43B"/>
            <path d="M956.075 1000L926.075 1000L925.995 0.00241209L955.995 0L956.075 1000Z" fill="#F5F5F5"/>
            <path d="M75.0803 1000.97L45.0803 1000.98L45 2.75873L75 2.75632L75.0803 1000.97Z" fill="#F5F5F5"/>

        </svg>
    );
}

export default VerticalRoad;
