import * as React from "react";
import {IIncidents} from "../Types/Incidents";

interface IncidentProps {
    incident: IIncidents
    tileSize: number
}

function SVGComponent(props: IncidentProps) {
    const inc: IIncidents = props.incident
    const tileSize = props.tileSize

    return (
        <svg
            x={inc.x * tileSize - (tileSize / 4)}
            y={inc.y * tileSize - (tileSize / 4)}
            width={tileSize / 2}
            height={tileSize / 2}
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <g transform="translate(0 -1028.4)">
                <path
                    d="m10.286 1030.4-5.3466 18h5.3466 3.564 5.346l-5.346-18h-3.564z"
                    fill="#ecf0f1"
                />
                <path
                    d="m4 19l-2 2h20l-2-2h-16z"
                    transform="translate(0 1028.4)"
                    fill="#f39c12"
                />
                <path
                    d="m10.286 1030.4-0.8913 3h5.3463l-0.891-3h-3.564zm-2.0886 7-1.1974 4h10.136l-1.198-4h-7.7406zm-2.3669 8-0.8911 3h5.3466 3.564 5.346l-0.891-3h-12.474z"
                    fill="#e67e22"
                />
                <path
                    d="m10.286 1030.4-5.3466 18h5.3466 1.782v-18h-1.782z"
                    fill="#bdc3c7"
                />
                <path
                    d="m10.286 1030.4-0.8913 3h2.6733v-3h-1.782zm-2.0886 7-1.1974 4h5.068v-4h-3.8706zm-2.3669 8-0.8911 3h5.3466 1.782v-3h-6.2375z"
                    fill="#d35400"
                />
                <rect height={1} width={20} y={1049.4} x={2} fill="#e67e22"/>
            </g>
        </svg>
    )
}

export default SVGComponent;
