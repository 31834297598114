import * as React from "react";


function VerticalRoad1() {


    return (
        <svg

            viewBox="0 0 1000 1000"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">

            <rect width="1000" height="1000" fill="#808080"/>
            <rect x="45" width="30" height="1000" fill="#F5F5F5"/>
            <rect x="926" width="30" height="1000" fill="#F5F5F5"/>
            <rect x="472" width="30" height="1000" fill="#E5F43B"/>

        </svg>
    );
}

export default VerticalRoad1;
