const wss_url = "wss://alivecode.ca/iotgateway/";
const api_url = "https://alivecode.ca/api";

const ws = new WebSocket(wss_url);

const id = localStorage.getItem("collected_id"); // id de l'objet sur alive code

const token = localStorage.getItem("collected_token");
ws.onopen = function open() {
    console.log("hello, connected")
    ws.send(
        JSON.stringify({
            event: "connect_object",
            data: {
                id,
                token
            },
        })
    );
}

ws.onclose = function closed(d) {
    console.log(d)
}

const defaultMap = [
    {
        "x": 2,
        "y": 0,
        "id": 1,
        "tile": 9
    },
    {
        "x": 3,
        "y": 0,
        "id": 2,
        "tile": 2
    },
    {
        "x": 1,
        "y": 0,
        "id": 3,
        "tile": 2
    },
    {
        "x": 4,
        "y": 0,
        "id": 4,
        "tile": 8
    },
    {
        "x": 0,
        "y": 0,
        "id": 5,
        "tile": 5
    },
    {
        "x": 4,
        "y": 1,
        "id": 6,
        "tile": 4
    },
    {
        "x": 4,
        "y": 2,
        "id": 7,
        "tile": 4
    },
    {
        "x": 0,
        "y": 1,
        "id": 8,
        "tile": 4
    },
    {
        "x": 0,
        "y": 2,
        "id": 9,
        "tile": 4
    },
    {
        "x": 4,
        "y": 3,
        "id": 10,
        "tile": 7
    },
    {
        "x": 0,
        "y": 3,
        "id": 11,
        "tile": 6
    },
    {
        "x": 1,
        "y": 3,
        "id": 12,
        "tile": 2
    },
    {
        "x": 3,
        "y": 3,
        "id": 13,
        "tile": 2
    },
    {
        "x": 2,
        "y": 3,
        "id": 14,
        "tile": 9,
        "signs": {
            "east": true,
            "type": 14,
            "west": true,
            "north": true,
            "south": false,
            "stateLightEW": "off",
            "stateLightNS": "off"
        }
    },
    {
        "x": 2,
        "y": 4,
        "id": 15,
        "tile": 4
    },
    {
        "x": 3,
        "y": 2,
        "id": 16,
        "tile": 13
    },
    {
        "x": 3,
        "y": 1,
        "id": 17,
        "tile": 13
    },
    {
        "x": 1,
        "y": 2,
        "id": 20,
        "tile": 13
    },
    {
        "x": 1,
        "y": 1,
        "id": 21,
        "tile": 13
    },
    {
        "x": 2,
        "y": 5,
        "id": 22,
        "tile": 9
    },
    {
        "x": 2,
        "y": 2,
        "id": 18,
        "tile": 19
    },
    {
        "x": 2,
        "y": 1,
        "id": 19,
        "tile": 20
    }
]

const defaultIncidents = [
    {
        "x": 1,
        "y": 1,
        "id": 1,
        "type": "generic"
    }
    ]

// img : string,
//     title : string,
//
//     link : string,
//     description : string,
//
//     hashtags : []

const articles  = [
    {
        img: "https://lrima.cmaisonneuve.qc.ca/wp-content/uploads/2023/06/im_5-1170x878.jpg",
        title:"LRIMa Est Lauréat À L’OCTAS 2023 Réseau Action TI Pour Son Projet La Ville Du Futur",
        link:"https://lrima.cmaisonneuve.qc.ca/2023/06/02/lrima-est-laureat-a-loctas-2023-reseau-action-ti-pour-son-projet-la-ville-du-futur/",
        hashtags: ["LRIMa", "iot", "ai", "smartcity"]

    },
    {
        img: "https://lrima.cmaisonneuve.qc.ca/wp-content/uploads/2023/05/sept-etudiants-lrima-finalistes.jpg",
        title:"Sept Étudiants De LRIMa En Finale Au Concours Octas 2023",
        link:"https://lrima.cmaisonneuve.qc.ca/2023/05/15/sept-etudiants-lrima-finalistes-du-concours-octas-2023-le-reseau-ti/",
        hashtags: ["LRIma","iot", "ai", "smartcity","Octas","Finalistes"]

    },
    {
        img: "https://lrima.cmaisonneuve.qc.ca/wp-content/uploads/2022/09/VilleIntelligente-1170x779.jpg",
        title:"LAURÉAT Forces Avenir Sciences Et Technologies 2022 : Ville Intelligente",
        link:"https://lrima.cmaisonneuve.qc.ca/2022/09/30/laureat-forces-avenir-sciences-et-technologies-2022-ville-intelligente/",
        hashtags: ["LRIMa", "ForcesAvenir", "ai", "iot","AzmourYousra"]

    },
    {
        img: "https://lrima.cmaisonneuve.qc.ca/wp-content/uploads/2022/05/certifiact2-1170x878.jpg",
        title:"Lauréat Local Au Collège Maisonneuve Et Parmi Les 3 Finalistes Pour Le GALA Forces Avenir : Projet Ville Intelligente",
        link:"https://lrima.cmaisonneuve.qc.ca/2022/05/10/laureat-local-au-college-maisonneuve-et-finaliste-pour-le-gala-forces-avenir-en-septembre-prochain-2022-avenir-sciences-et-technologies-projet-ville-intelligente/https://lrima.cmaisonneuve.qc.ca/2022/05/10/laureat-local-au-college-maisonneuve-et-finaliste-pour-le-gala-forces-avenir-en-septembre-prochain-2022-avenir-sciences-et-technologies-projet-ville-intelligente/",
        hashtags: ["LRIMa", "ai", "smartcity","Laureat"]

    },
    {
        img: "https://lrima.cmaisonneuve.qc.ca/wp-content/uploads/2022/05/MicrosoftTeams-image-1-Copie.jpg",
        title:"LRIMA Au Cœur DES PROJETS DE COOPÉRATION INTERNATIONALE",
        link:"https://lrima.cmaisonneuve.qc.ca/2022/05/09/lrima-au-coeur-des-projets-de-cooperation-internationale/",
        hashtags: ["LRIMa", "International", "ai", "Cooperation","ESP-ÉPE"]

    },
    {
        img: "https://lrima.cmaisonneuve.qc.ca/wp-content/uploads/2022/04/construction-et-discussion-sur-la-ville-1170x710.jpg",
        title:"Financement Énergie Scolaire: Conception Et Construction D’une Ville Intelligente",
        link:"https://lrima.cmaisonneuve.qc.ca/2022/05/09/lrima-au-coeur-des-projets-de-cooperation-internationale/",
        hashtags: ["LRIMa", "IoT", "ai", "Conception"]

    },
    {
        img: "https://lrima.cmaisonneuve.qc.ca/wp-content/uploads/2022/03/LRIMAvisite1-1170x878.jpg",
        title:"La Synergie Entre La Formation Et La Recherche: Démos IoT Et Ville Intelligente Au LRIMa",
        link:"https://lrima.cmaisonneuve.qc.ca/2022/03/10/certification-a-la-recherche-pour-les-etudiants-chercheurs-lrima/",
        hashtags: ["LRIMa", "Recherche", "IoT", "Formation", "Démo"]

    },
    ]
export {ws, id, token, wss_url, api_url, defaultMap, defaultIncidents, articles};
