import * as React from "react";


function TurnRoad3() {


    return (
        <svg

            viewBox="0 0 1000 1000"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">

                <path d="M1001.1 1001.1L1.09961 1001.1L1.09961 1.10107L1001.1 1.10107L1001.1 1001.1Z" fill="#808080"/>
                <path d="M947.203 957.169L2.20337 957.169V927.169L947.203 927.169V957.169Z" fill="#F5F5F5"/>
                <path d="M501.063 501.727L1.06299 501.727V471.727H501.063V501.727Z" fill="#E5F43B"/>
                <path d="M530.474 1.06427L530.474 501.064H500.474L500.474 1.06427H530.474Z" fill="#E5F43B"/>
                <path d="M956.043 0L956.043 956H926.043L926.043 0H956.043Z" fill="#F5F5F5"/>
                <path d="M73.9436 2.20612V77.2061H43.9436V2.20612H73.9436Z" fill="#F5F5F5"/>
                <path d="M74 77.2611H0V47.2611H74V77.2611Z" fill="#F5F5F5"/>

        </svg>
    );
}

export default TurnRoad3;
