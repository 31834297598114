import * as React from "react";


function ParkingSign() {


    return (
        <svg

            viewBox="0 0 480 480"
            xmlns="http://www.w3.org/2000/svg">
            <g>
      <path
        style={{
          fill: "#695A69",
        }}
        d="M231.898,328.993c0.485-0.142,0.991-0.233,1.517-0.233H240v129.635h-16.204l-16.205,10.803V480 h64.817v-10.803l-16.204-10.803h0V350.365l-4.378-17.514c-0.474-1.895-1.921-3.329-3.724-3.858V167.445h-16.204V328.993z"
      />
      <path
        style={{
          fill: "#826E77",
        }}
        d="M240,328.76h-6.585c-0.526,0-1.031,0.091-1.517,0.233c-1.803,0.529-3.25,1.964-3.723,3.858 l-4.379,17.514v108.029h0H240V328.76z"
      />
      <path
        style={{
          fill: "#6EB1E2",
        }}
        d="M317.722,0h-71.62h-12.204h-71.62c-3.314,0-6,2.686-6,6v155.445c0,3.314,2.686,6,6,6h69.62h16.204 h69.62c3.314,0,6-2.686,6-6V6C323.722,2.686,321.036,0,317.722,0z M312.919,151.24c0,2.978-2.422,5.401-5.401,5.401H172.482 c-2.979,0-5.401-2.424-5.401-5.401V16.204c0-2.978,2.422-5.401,5.401-5.401h135.036c2.979,0,5.401,2.424,5.401,5.401V151.24z"
      />
      <path
        style={{
          fill: "#6EB1E2",
        }}
        d="M172.482,16.204V151.24h135.036V16.204H172.482z M277.81,70.68c0,18.583-13.154,31.56-31.988,31.56 h-17.885v30.095h-18.681V39.242h36.992C264.832,39.242,277.81,52.171,277.81,70.68z"
      />
      <path
        style={{
          fill: "#6EB1E2",
        }}
        d="M242.648,55.786h-14.71v29.971h14.71c10.431,0,16.176-5.332,16.176-15.016 C258.824,58.38,250.027,55.786,242.648,55.786z"
      />
      <path
        style={{
          fill: "#FFFFFF",
        }}
        d="M246.249,39.242h-36.992v93.093h18.681V102.24h17.885c18.833,0,31.988-12.977,31.988-31.56 C277.81,52.171,264.832,39.242,246.249,39.242z M258.824,70.741c0,9.684-5.745,15.016-16.176,15.016h-14.71V55.786h14.71 C250.027,55.786,258.824,58.38,258.824,70.741z"
      />
      <path
        style={{
          fill: "#FFFFFF",
        }}
        d="M307.518,10.803H172.482c-2.979,0-5.401,2.424-5.401,5.401V151.24c0,2.978,2.422,5.401,5.401,5.401 h135.036c2.979,0,5.401-2.424,5.401-5.401V16.204C312.919,13.227,310.497,10.803,307.518,10.803z M307.518,151.24H172.482V16.204 h135.036V151.24z"
      />
    </g>
        </svg>
    );
}

export default ParkingSign;
