import * as React from "react";


function GiveWaySign() {


    return (
        <svg
            viewBox="-0.525 -0.631 576 507"
            enableBackground="new -0.525 -0.631 576 507"
            xmlns="http://www.w3.org/2000/svg">
            <path fill="#FFFFFF" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" d="M570.302,46.946L314.292,490.37
	c-5.531,9.58-15.754,15.482-26.816,15.482s-21.284-5.902-26.816-15.482L4.648,46.946c-5.531-9.58-5.531-21.384,0-30.964
	C10.18,6.402,20.401,0.5,31.464,0.5h512.021c11.063,0,21.285,5.901,26.816,15.482S575.833,37.366,570.302,46.946z"/>
<path fill="#E41A3C" stroke="#FF0000" strokeWidth="0.1764" strokeLinecap="round" strokeLinejoin="round" d="M15.33,40.675
	l256.101,443.581c3.31,5.732,9.425,9.264,16.044,9.264s12.734-3.531,16.044-9.264L559.621,40.675c3.31-5.732,3.31-12.794,0-18.526
	s-9.425-9.263-16.044-9.263H31.374c-6.619,0-12.735,3.531-16.044,9.264C12.02,27.881,12.02,34.943,15.33,40.675z"/>
<polygon fill="#FFFFFF" stroke="#FF0000" strokeWidth="0.1764" strokeLinecap="round" strokeLinejoin="round" points="
	287.475,407.388 87.985,61.86 486.964,61.86 "/>
<path d="M197.524,134.672v-16.145h30.027v33.084c-5.748,4.835-10.831,8.125-15.25,9.873c-4.418,1.747-9.659,2.62-15.721,2.62
	c-7.464,0-13.548-1.57-18.252-4.711c-4.704-3.141-8.351-7.816-10.938-14.028c-2.588-6.21-3.882-13.339-3.882-21.386
	c0-8.47,1.416-15.836,4.247-22.101c2.831-6.264,6.978-11.019,12.44-14.266c4.261-2.505,9.995-3.758,17.201-3.758
	c6.949,0,12.146,0.776,15.593,2.329c3.446,1.553,6.306,3.962,8.579,7.226c2.273,3.265,3.982,7.403,5.126,12.414l-18.746,4.129
	c-0.772-2.929-2.08-5.169-3.925-6.723c-1.844-1.553-4.197-2.33-7.056-2.33c-4.262,0-7.657,1.827-10.188,5.479
	c-2.531,3.653-3.796,9.432-3.796,17.336c0,8.399,1.28,14.398,3.839,17.998c2.559,3.6,6.126,5.399,10.703,5.399
	c2.173,0,4.247-0.388,6.22-1.165c1.973-0.776,4.232-2.099,6.777-3.97v-7.305H197.524z"/>
<path d="M239.862,85.178h19.475v77.604h-19.475V85.178z"/>
<path d="M267.101,85.178h20.313l14.154,55.847l13.973-55.847h19.722l-23.35,77.604H290.85L267.101,85.178z"/>
<path d="M341.568,85.178h52.076v16.569h-32.601v12.334h30.241v15.828h-30.241v15.298h33.544v17.575h-53.02V85.178z"/>
<path d="M214.447,202.242h12.257l4.413,28.853l6.459-28.853h12.214l6.475,28.814l4.418-28.814h12.194l-9.207,51.539h-12.655
	l-7.325-32.449l-7.296,32.449h-12.655L214.447,202.242z"/>
<path d="M302.769,245.273h-14.651l-2.036,8.508h-13.177l15.697-51.539h14.077l15.694,51.539h-13.515L302.769,245.273z
	 M300.092,234.129l-4.609-18.527l-4.563,18.527H300.092z"/>
<path d="M313.3,202.242h14.335l8.414,17.387l8.432-17.387h14.258L342.5,232.195v21.586h-12.934v-21.586L313.3,202.242z"/>

        </svg>
    );
}

export default GiveWaySign;
