import * as React from "react";


function IntersectionRoad1() {


    return (
        <svg

            viewBox="0 0 1000 1000"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
                <path d="M1 0H1001V1000H1V0Z" fill="#808080"/>
                <path d="M1 42H75V72H1V42Z" fill="#F5F5F5"/>
                <path d="M45 72V0H75V72H45Z" fill="#F5F5F5"/>
                <path d="M0.632324 923H75.6323V953H0.632324V923Z" fill="#F5F5F5"/>
                <path d="M928 927H1001V957H928V927Z" fill="#F5F5F5"/>
                <path d="M45.6323 1000V929H75.6323V1000H45.6323Z" fill="#F5F5F5"/>
                <path d="M928 1000V929H958V1000H928Z" fill="#F5F5F5"/>
                <path d="M926.997 46H1002V76H926.997V46Z" fill="#F5F5F5"/>
                <path d="M926.997 71V0H956.997V71H926.997Z" fill="#F5F5F5"/>
                <path d="M926 76H956V531H926V76Z" fill="#F5F5F5"/>
                <path d="M46 497H76V923H46V497Z" fill="#F5F5F5"/>
        </svg>
    );
}

export default IntersectionRoad1;
