import * as React from "react";

export default function Triangulation({a, d, tilesize}: any) {

    // math stuff
    const
        height = 100,
        width = 100,
        c = Math.sqrt(height ** 2 + width ** 2) / 2,
        b = Math.sqrt(c ** 2 - a ** 2),
        f = Math.sqrt(c ** 2 - d ** 2)

    // display stuff
    const
        curveWidth = 0.25,
        colorPositiveDiagonal = "#0bd3ff",
        colorNegativeDiagonal = "#02ee2e",
        minHeight = 0 - height / 2,
        minWidth = 0 - width / 2


    function crToBezier(points: any) {
        const beziers = [];

        for (let i = 0; i < points.length - 3; i++) {
            //  NOTE THE i++ HERE! We're performing a sliding window conversion.
            let [p1, p2, p3, p4] = points.slice(i);
            beziers.push({
                start: p2,
                end: p3,
                c1: {x: p2.x + (p3.x - p1.x) / 6, y: p2.y + (p3.y - p1.y) / 6},
                c2: {x: p3.x - (p4.x - p2.x) / 6, y: p3.y - (p4.y - p2.y) / 6}
            })
        }

        return beziers;
    }

    function hyperbolaToPolyBezier(a: number, b: number, inf = 300) {
        const points = [],
            a2 = a ** 2,
            b2 = b ** 2,
            step = inf / 10;

        let x, y, x2

        for (x = a + inf; x > a; x -= step) {
            x2 = x ** 2;
            y = -Math.sqrt(b2 * x2 / a2 - b2);
            points.push({x, y});
        }

        for (x = a; x < a + inf; x += step) {
            x2 = x ** 2;
            y = Math.sqrt(b2 * x2 / a2 - b2);
            points.push({x, y});
        }

        return crToBezier(points);
    }

    // lists of Bézier curves control points
    // each list fits 2 opposed towers
    const curveTopRightBottomLeft = hyperbolaToPolyBezier(a, b)
    const curveTopLeftBottomRight = hyperbolaToPolyBezier(d, f)

    // define a grid with interval marks for debug purposes
    function setGrid() {
        const marks = [
            <rect x={-50} y={-50} width="100" height="100" stroke="black" strokeWidth={1}/>,
            <line x1={-50} y1={0} x2={50} y2={0} stroke={"black"} strokeWidth={0.5}/>,
            <line x1={0} y1={-50} x2={0} y2={50} stroke={"black"} strokeWidth={0.5}/>
        ]

        for (let i = -50; i < 51; i += 10) {
            marks.push(<line x1={i} y1={minHeight} x2={i} y2={height} stroke={"black"} strokeWidth={0.1}/>)
        }

        for (let i = -50; i < 51; i += 10) {
            marks.push(<line x1={minWidth} y1={i} x2={width} y2={i} stroke={"black"} strokeWidth={0.1}/>)
        }

        return marks
    }


    return (
        <svg viewBox={`${minWidth} ${minHeight + tilesize/100} ${width} ${height}`} width="100%" height="100%" x={0} y={0}>
            <>
                {setGrid()}

                {/* TOP RIGHT */}
                {curveTopRightBottomLeft && curveTopRightBottomLeft.map((set) => {
                    return <g transform={"rotate(-45, 0, 0) translate(0 0)"}>
                        <path
                            d={`M ${set.start.x},${set.start.y} C ${set.c1.x},${set.c1.y} ${set.c2.x},${set.c2.y} ${set.end.x},${set.end.y}`}
                            strokeWidth={curveWidth}
                            stroke={colorNegativeDiagonal}/>
                    </g>
                })}

                {/* BOTTOM LEFT aka top right but mirrored */}
                {curveTopRightBottomLeft && curveTopRightBottomLeft.map((set) => {
                    return <g transform={"rotate(-225, 0, 0) translate(0 0)"}>
                        <path
                            d={`M ${set.start.x},${set.start.y} C ${set.c1.x},${set.c1.y} ${set.c2.x},${set.c2.y} ${set.end.x},${set.end.y}`}
                            strokeWidth={curveWidth}
                            stroke={colorNegativeDiagonal}/>
                    </g>
                })}

                {/* BOTTOM RIGHT */}
                {curveTopLeftBottomRight && curveTopLeftBottomRight.map((set) => {
                    return <g transform={"rotate(45, 0, 0) translate(0 0)"}>
                        <path
                            d={`M ${set.start.x},${set.start.y} C ${set.c1.x},${set.c1.y} ${set.c2.x},${set.c2.y} ${set.end.x},${set.end.y}`}
                            strokeWidth={curveWidth}
                            stroke={colorPositiveDiagonal}/>
                    </g>
                })}

                {/* TOP LEFT aka bottom right but mirrored */}
                {curveTopLeftBottomRight && curveTopLeftBottomRight.map((set) => {
                    return <g transform={"rotate(225, 0, 0) translate(0 0)"}>
                        <path
                            d={`M ${set.start.x},${set.start.y} C ${set.c1.x},${set.c1.y} ${set.c2.x},${set.c2.y} ${set.end.x},${set.end.y}`}
                            strokeWidth={curveWidth}
                            stroke={colorPositiveDiagonal}/>
                    </g>
                })}

                {/*<g transform={"rotate(-45, 0, 0) translate(0 0)"}>*/}
                {/*    <circle r={0.5} cx={30.973} cy={-16.438} fill={"red"}/>*/}
                {/*</g>*/}

            </>
        </svg>
    )
}
