import React, { Suspense, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import '/node_modules/flag-icons/css/flag-icons.min.css';
import BuildingSvg from "../comps/BuildingSvg";

const ConnectionPage: React.FC = () => {
    const [id, setId] = useState('');
    const [token, setToken] = useState('');
    const { t, i18n } = useTranslation();
    const location = useLocation();

    useEffect(() => {
        //extract id and token
        const searchParams = new URLSearchParams(location.search);
        const idParam = searchParams.get('id') || '';
        const tokenParam = searchParams.get('token') || '';

        setId(idParam);
        setToken(tokenParam);

        //auto connect
        if (idParam && tokenParam) {
            handleConnect(idParam, tokenParam);
        }
    }, [location.search]);

    const handleConnect = async (id: string, token: string) => {
        localStorage.setItem('collected_id', id);
        localStorage.setItem('collected_token', token);
        window.location.href = `/main?id=${id}&token=${token}`;
    };

    useEffect(() => {
        if (i18n.language === 'ar') {
            document.body.dir = 'rtl';
        } else {
            document.body.dir = 'ltr';
        }
        //document.body.style.overflow = 'hidden'; // Set overflow to hidden
        return () => {
            document.body.style.overflow = 'auto'; // Reset overflow when component unmounts
        };
    }, [i18n.language]);

    return (
        <Suspense fallback="Loading....">
            <div className="flex flex-col h-screen">
                <div className="flex-grow flex h-full">
                    <div className="max-w-7xl h-full flex items-center justify-center p-10">
                        <iframe
                            width="750"
                            height="400"
                            src="https://www.youtube.com/embed/WLPV0lk77A0?si=Aa4g9yiMX0mVDzWR"
                            title="YouTube video player"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerPolicy="strict-origin-when-cross-origin"
                            allowFullScreen
                        ></iframe>
                    </div>

                    <div className="h-5/6 border-r-4 border-gray-300"></div>

                    <div className="w-1/2 flex flex-col items-center justify-center">
                        <h1 className="text-8xl font-bold mb-6">
                            <span className="inline-block">AliveC</span>
                            <span className="inline-block"><BuildingSvg /></span>
                            <span className="inline-block">ty</span>
                        </h1>
                        <div className="flex flex-col space-y-6">
                            <input
                                type="text"
                                placeholder={t('enter_the_id')}
                                className="border p-2 w-80"
                                value={id}
                                onChange={(e) => setId(e.target.value)}
                            />
                            <input
                                type="password"
                                placeholder={t('enter_the_token')}
                                className="border p-2"
                                value={token}
                                onChange={(e) => setToken(e.target.value)}
                            />

                            <button onClick={() => handleConnect(id, token)} className="bg-blue-500 text-white px-4 py-2 rounded">
                                {t('login')}
                            </button>
                        </div>
                    </div>
                </div>

                {/* Section for horizontally scrollable pictures */}
                {/*<div className="flex overflow-x-auto p-4 bg-gray-200">
                    <img src={picture1} alt="Picture 1" className="mr-4" />
                    <img src={picture2} alt="Picture 2" className="mr-4" />
                    <img src={picture3} alt="Picture 3" className="mr-4" />
                    <img src={picture4} alt="Picture 3" className="mr-4" />
                    <img src={picture3} alt="Picture 3" className="mr-4" />
                    <img src={picture4} alt="Picture 3" className="mr-4" />
                </div>*/}
            </div>
        </Suspense>
    );
};

export default ConnectionPage;
