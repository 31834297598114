import * as React from "react";


function HorizentalRoad() {

    return (
        <svg
            viewBox="0 0 1000 1000"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">

                <rect width="1000" height="1000" fill="#F5F5F5"/>
                <rect x="1002" width="1000" height="1000" transform="rotate(90 1002 0)" fill="#808080"/>
                <rect x="984.044" y="498.759" width="30" height="150" transform="rotate(90 984.044 498.759)" fill="#E5F43B"/>
                <rect x="772.045" y="499.406" width="30" height="150" transform="rotate(90 772.045 499.406)" fill="#E5F43B"/>
                <rect x="567.046" y="500.03" width="30" height="150" transform="rotate(90 567.046 500.03)" fill="#E5F43B"/>
                <rect x="355.047" y="500.677" width="30" height="150" transform="rotate(90 355.047 500.677)" fill="#E5F43B"/>
                <rect x="150.048" y="501.277" width="30" height="134" transform="rotate(90 150.048 501.277)" fill="#E5F43B"/>
                <rect x="1000.66" y="45.002" width="30" height="1000" transform="rotate(90 1000.66 45.002)" fill="#F5F5F5"/>
                <rect x="1003.34" y="925.998" width="30" height="1000" transform="rotate(90 1003.34 925.998)" fill="#F5F5F5"/>

        </svg>
    );
}

export default HorizentalRoad;
