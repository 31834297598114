import {ITile} from "../Types/Tile";
import React, {useContext} from "react";
import {TrafficLightsContext} from "../App";
import StopSign from "../Tiles/TrafficSigns/StopSign";
import GiveWaySign from "../Tiles/TrafficSigns/GiveWaySign";
import ParkingSign from "../Tiles/TrafficSigns/ParkingSign";
import CrossWalk from "../Tiles/CrossWalk";
import TrafficLight from "../Tiles/TrafficSigns/TrafficLight";

function RenderSigns(tile: ITile) {
    const signSize = tile.signs?.type === 18 ? 400 : 300

    const lights = useContext(TrafficLightsContext)

    const pos = `${tile.x}:${tile.y}` as keyof typeof lights
    const temp = lights[pos] ? lights[pos] : {E: "off", N: "off", S: "off", W: "off"}

    function selectSign(type: number, state: string) {
        switch (type) {
            case 14:
                return <StopSign/>;
            case 15:
                return <GiveWaySign/>;
            case 16:
                return <ParkingSign/>;
            case 17:
                return <CrossWalk/>;
            case 18:
                return <TrafficLight state={state}/>
        }
    }

    return <svg  x={0} y={0} viewBox={"0 0 1000 1000"}>
        {tile.signs &&
            <svg viewBox={"0 0 1000 1000"}>
                {tile.signs.north &&
                    <svg height={signSize} width={signSize} x={725 - signSize / 2} y={850 - signSize / 2}>
                        <g transform={`rotate(0 ${signSize/2} ${signSize/2})`}>{selectSign(tile.signs.type, temp.N)}</g>
                    </svg>}
                {tile.signs.south &&
                    <svg height={signSize} width={signSize} x={275 - signSize / 2} y={150 - signSize / 2}>
                        <g transform={`rotate(180 ${signSize/2} ${signSize/2})`}>{selectSign(tile.signs.type, temp.S)}</g>
                    </svg>}
                {tile.signs.east &&
                    <svg height={signSize} width={signSize} x={150 - signSize / 2} y={725 - signSize / 2}>
                        <g transform={`rotate(90 ${signSize/2} ${signSize/2})`}>{selectSign(tile.signs.type, temp.E)}</g>
                    </svg>}
                {tile.signs.west &&
                    <svg height={signSize} width={signSize} x={850 - signSize / 2} y={275 - signSize / 2}>
                        <g transform={`rotate(270 ${signSize/2} ${signSize/2})`}>{selectSign(tile.signs.type, temp.W)}</g>
                    </svg>}
            </svg>
        }

    </svg>
}

export default RenderSigns
