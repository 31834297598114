import * as React from "react";


function Grass() {


    return (
        <svg
            viewBox="0 0 1000 1000"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">

               <rect width="1000" height="1000" fill="#36BC33"/>

        </svg>
    );
}

export default Grass;
