import * as React from "react";


function IntersectionRoad2() {


    return (
        <svg

            viewBox="0 0 1000 1000"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
                <path d="M1 0H1001V1000H1V0Z" fill="#808080"/>
                <path d="M1 42H75V72H1V42Z" fill="#F5F5F5"/>
                <path d="M45 72V0H75V72H45Z" fill="#F5F5F5"/>
                <path d="M0.632324 923H75.6323V953H0.632324V923Z" fill="#F5F5F5"/>
                <path d="M928 927H1001V957H928V927Z" fill="#F5F5F5"/>
                <path d="M45.6323 1000V929H75.6323V1000H45.6323Z" fill="#F5F5F5"/>
                <path d="M928 1000V929H958V1000H928Z" fill="#F5F5F5"/>
                <path d="M926.997 46H1002V76H926.997V46Z" fill="#F5F5F5"/>
                <path d="M926.997 71V0H956.997V71H926.997Z" fill="#F5F5F5"/>
                <path d="M46 501H76V584H46V501Z" fill="#F5F5F5"/>
                <path d="M46 612H76V695H46V612Z" fill="#F5F5F5"/>
                <path d="M46 729H76V812H46V729Z" fill="#F5F5F5"/>
                <path d="M46 841H76V924H46V841Z" fill="#F5F5F5"/>
                <path d="M927 75H957V158H927V75Z" fill="#F5F5F5"/>
                <path d="M927 186H957V269H927V186Z" fill="#F5F5F5"/>
                <path d="M927 303H957V386H927V303Z" fill="#F5F5F5"/>
                <path d="M927 415H957V498H927V415Z" fill="#F5F5F5"/>
                <path d="M681 480C681 577.754 600.411 657 501 657C401.589 657 321 577.754 321 480C321 382.246 401.589 303 501 303C600.411 303 681 382.246 681 480Z" fill="#36BC33"/>
                <path d="M48 3H72V39H48V3ZM78 39V3H923.997V43V46V71V74V76V79H924V158V161H927H957H960V158V79H998V924H928H925V926V927V929V957V960V997H78.6323V956V953V929V927H79V924V841V838H76H46H43V841V920H4V75H42H45H75H78V72V42V39ZM72 45V69H48V45H72ZM42 45V69H4V45H42ZM42 39H4V3H42V39ZM929.997 43V3H953.997V43H929.997ZM929.997 49H953.997V68H929.997V49ZM959.997 72V71V49H998V73H960V72H959.997ZM930 155V79H954V155H930ZM961 930H998V954H961V930ZM961 960H998V997H961V960ZM955 960V997H931V960H955ZM72.6323 956V997H48.6323V956H72.6323ZM42.6323 956V997H4V956H42.6323ZM42.6323 950H4V926H42.6323V929V950ZM72.6323 950H48.6323V932H72.6323V950ZM73 844V920H49V844H73ZM955 954H931V932H955V954ZM959.997 3H998V43H959.997V3ZM46 498H43V501V584V587H46H76H79V584V501V498H76H46ZM46 609H43V612V695V698H46H76H79V695V612V609H76H46ZM46 726H43V729V812V815H46H76H79V812V729V726H76H46ZM927 183H924V186V269V272H927H957H960V269V186V183H957H927ZM927 300H924V303V386V389H927H957H960V386V303V300H957H927ZM927 412H924V415V498V501H927H957H960V498V415V412H957H927ZM49 581V504H73V581H49ZM49 692V615H73V692H49ZM49 809V732H73V809H49ZM930 266V189H954V266H930ZM930 383V306H954V383H930ZM930 495V418H954V495H930ZM678 480C678 576.05 598.802 654 501 654C403.198 654 324 576.05 324 480C324 383.95 403.198 306 501 306C598.802 306 678 383.95 678 480ZM501 660C602.02 660 684 579.459 684 480C684 380.541 602.02 300 501 300C399.98 300 318 380.541 318 480C318 579.459 399.98 660 501 660Z" stroke="#F5F5F5" strokeWidth="6"/>
                <rect x="1" width="1000" height="1000" fill="#808080"/>
                <rect x="1" y="42" width="74" height="30" fill="#F5F5F5"/>
                <rect x="45" y="72" width="72" height="30" transform="rotate(-90 45 72)" fill="#F5F5F5"/>
                <rect x="0.632324" y="923" width="75" height="30" fill="#F5F5F5"/>
                <rect x="928" y="927" width="73" height="30" fill="#F5F5F5"/>
                <rect x="45.6323" y="1000" width="71" height="30" transform="rotate(-90 45.6323 1000)" fill="#F5F5F5"/>
                <rect x="928" y="1000" width="71" height="30" transform="rotate(-90 928 1000)" fill="#F5F5F5"/>
                <rect x="926.997" y="46" width="75" height="30" fill="#F5F5F5"/>
                <rect x="926.997" y="71" width="71" height="30" transform="rotate(-90 926.997 71)" fill="#F5F5F5"/>
                <rect x="46" y="501" width="30" height="83" fill="#F5F5F5"/>
                <rect x="46" y="612" width="30" height="83" fill="#F5F5F5"/>
                <rect x="46" y="729" width="30" height="83" fill="#F5F5F5"/>
                <rect x="46" y="841" width="30" height="83" fill="#F5F5F5"/>
                <rect x="927" y="75" width="30" height="83" fill="#F5F5F5"/>
                <rect x="927" y="186" width="30" height="83" fill="#F5F5F5"/>
                <rect x="927" y="303" width="30" height="83" fill="#F5F5F5"/>
                <rect x="927" y="415" width="30" height="83" fill="#F5F5F5"/>
                <path d="M678 480C678 576.05 598.802 654 501 654C403.198 654 324 576.05 324 480C324 383.95 403.198 306 501 306C598.802 306 678 383.95 678 480Z" fill="#36BC33" stroke="#F5F5F5" strokeWidth="6"/>
        </svg>
    );
}

export default IntersectionRoad2;
