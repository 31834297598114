import * as React from "react";


function IntersectionRoad3() {


    return (
        <svg

            viewBox="0 0 1000 1000"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">

        <rect width="1000" height="1000" fill="#808080"/>
        <rect y="44" width="1000" height="30" fill="#F5F5F5"/>
        <rect y="925" width="75" height="30" fill="#F5F5F5"/>
        <rect x="45" y="1000" width="66" height="30" transform="rotate(-90 45 1000)" fill="#F5F5F5"/>
        <rect x="927" y="1000" width="74" height="30" transform="rotate(-90 927 1000)" fill="#F5F5F5"/>
        <rect x="927" y="925" width="73" height="30" fill="#F5F5F5"/>
        <rect x="500" y="925" width="442" height="30" fill="#F5F5F5"/>

        </svg>
    );
}

export default IntersectionRoad3;
