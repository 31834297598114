import {TileProps} from "../Types/TileProps";
import {ITile} from "../Types/Tile";
import * as React from "react";

function Selected(props: TileProps) {
    const til: ITile = props.tile
    const onSelected: (x:number, y:number) => void = props.onSelected
    const tileSize = props.tileSize

    return (
        <svg
            onClick={() => onSelected(til.x, til.y)}
            x={til.x * tileSize}
            y={til.y * tileSize}
            width={tileSize}
            height={tileSize}
            viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg"
            fill="red"
            {...props}>

            <path
                style={{
                    stroke: "red",
                    strokeWidth: 2,
                }}
                d="M0 0h25m50 0h25M0 100h25m50 0h25M0 0v25m0 50v25M100 0v25m0 50v25"
            />
        </svg>
    );
}

export default Selected;
