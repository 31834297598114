import * as React from "react";


function TurnRoad4() {


    return (
        <svg
            viewBox="0 0 1000 1000"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
                <rect x="1000" y="1000" width="1000" height="1000" transform="rotate(-180 1000 1000)" fill="#808080"/>
                <rect x="927" y="1001" width="945" height="30" transform="rotate(-90 927 1001)" fill="#F5F5F5"/>
                <rect x="472" y="1001" width="502" height="30" transform="rotate(-90 472 1001)" fill="#E5F43B"/>
                <rect x="45" y="1000" width="74" height="30" transform="rotate(-90 45 1000)" fill="#F5F5F5"/>
                <rect x="45" y="1000" width="74" height="30" transform="rotate(-90 45 1000)" fill="#F5F5F5"/>
                <rect y="926" width="49" height="30" fill="#F5F5F5"/>
                <rect y="926" width="49" height="30" fill="#F5F5F5"/>
                <path d="M499 529L0 529V499L499 499V529Z" fill="#E5F43B"/>
                <rect x="957" y="74" width="957" height="30" transform="rotate(-180 957 74)" fill="#F5F5F5"/>
        </svg>
    );
}

export default TurnRoad4;
