import React from "react";

export default function Default() {
    return (
        <svg viewBox="0 0 76 76">
            <path
                fill="#000000"
                fillOpacity={0.803922}
                strokeWidth={0.2}
                strokeLinejoin="round"
                d="M 34.1791,16.3306C 31.6036,16.7816 29.1822,17.6806 26.9982,18.9443L 31.0904,26.0311C 32.4622,25.2374 33.9831,24.6728 35.6008,24.3895L 34.1791,16.3306 Z "
            />
            <path
                fill="#000000"
                fillOpacity={0.85098}
                strokeWidth={0.2}
                strokeLinejoin="round"
                d="M 26.9982,18.9443C 24.7715,20.2326 22.7916,21.9001 21.1466,23.8583L 27.4151,29.1177C 28.4482,27.8877 29.6918,26.8403 31.0904,26.0311L 26.9982,18.9443 Z "
            />
            <path
                fill="#000000"
                fillOpacity={0.901961}
                strokeWidth={0.2}
                strokeLinejoin="round"
                d="M 21.1466,23.8583C 19.5116,25.8049 18.2075,28.0389 17.3211,30.4735L 25.0123,33.2726C 25.569,31.7434 26.3881,30.3403 27.4151,29.1177L 21.1466,23.8583 Z "
            />
            <path
                fill="#000000"
                fillOpacity={0.152941}
                strokeWidth={0.2}
                strokeLinejoin="round"
                d="M 16,38C 16,40.6437 16.4663,43.1784 17.3211,45.5265L 25.0123,42.7274C 24.4754,41.2526 24.1825,39.6605 24.1825,38L 16,38 Z "
            />
            <path
                fill="#000000"
                fillOpacity={0.203922}
                strokeWidth={0.2}
                strokeLinejoin="round"
                d="M 17.3211,45.5265C 18.2075,47.9612 19.5115,50.1951 21.1466,52.1417L 27.4151,46.8824C 26.3881,45.6597 25.569,44.2566 25.0123,42.7274L 17.3211,45.5265 Z "
            />
            <path
                fill="#000000"
                fillOpacity={0.25098}
                strokeWidth={0.2}
                strokeLinejoin="round"
                d="M 21.1466,52.1417C 22.7915,54.1 24.7715,55.7674 26.9982,57.0558L 31.0904,49.9689C 29.6918,49.1597 28.4482,48.1124 27.4151,46.8824L 21.1466,52.1417 Z "
            />
            <path
                fill="#000000"
                fillOpacity={0.301961}
                strokeWidth={0.2}
                strokeLinejoin="round"
                d="M 26.9982,57.0558C 29.1822,58.3194 31.6036,59.2184 34.1791,59.6694L 35.6008,51.6105C 33.9831,51.3272 32.4622,50.7626 31.0904,49.9689L 26.9982,57.0558 Z "
            />
            <path
                fill="#000000"
                fillOpacity={0.403922}
                strokeWidth={0.2}
                strokeLinejoin="round"
                d="M 41.8209,59.6694C 44.3964,59.2184 46.8178,58.3194 49.0018,57.0557L 44.9109,49.9689C 43.5391,50.7626 42.0182,51.3272 40.4006,51.6105L 41.8209,59.6694 Z "
            />
            <path
                fill="#000000"
                fillOpacity={0.45098}
                strokeWidth={0.2}
                strokeLinejoin="round"
                d="M 49.0018,57.0557C 51.2285,55.7674 53.2085,54.0999 54.8534,52.1416L 48.5862,46.8824C 47.5531,48.1124 46.3095,49.1597 44.9109,49.9689L 49.0018,57.0557 Z "
            />
            <path
                fill="#000000"
                fillOpacity={0.501961}
                strokeWidth={0.2}
                strokeLinejoin="round"
                d="M 54.8534,52.1416C 56.4885,50.1951 57.7925,47.9611 58.6789,45.5265L 50.989,42.7274C 50.4323,44.2566 49.6132,45.6597 48.5862,46.8824L 54.8534,52.1416 Z "
            />
            <path
                fill="#000000"
                fillOpacity={0.65098}
                strokeWidth={0.2}
                strokeLinejoin="round"
                d="M 58.6788,30.4735C 57.7925,28.0389 56.4884,25.805 54.8534,23.8584L 48.5862,29.1177C 49.6132,30.3403 50.4323,31.7434 50.989,33.2726L 58.6788,30.4735 Z "
            />
            <path
                fill="#000000"
                fillOpacity={0.701961}
                strokeWidth={0.2}
                strokeLinejoin="round"
                d="M 54.8534,23.8584C 53.2085,21.9001 51.2285,20.2327 49.0018,18.9443L 44.9109,26.0311C 46.3095,26.8403 47.5531,27.8877 48.5862,29.1177L 54.8534,23.8584 Z "
            />
            <path
                fill="#000000"
                fillOpacity={0.701961}
                strokeWidth={0.2}
                strokeLinejoin="round"
                d="M 49.0018,18.9443C 46.8178,17.6806 44.3964,16.7817 41.8209,16.3306L 40.4006,24.3895C 42.0182,24.6728 43.5391,25.2374 44.9109,26.0311L 49.0018,18.9443 Z "
            />
            <path
                fill="#000000"
                fillOpacity={0.352941}
                strokeWidth={0.2}
                strokeLinejoin="round"
                d="M 34.1791,59.6694C 35.4201,59.8867 36.6968,60 38,60C 39.3032,60 40.5799,59.8867 41.8209,59.6694L 40.4006,51.6105C 39.6211,51.747 38.8192,51.8182 38.0007,51.8182C 37.1821,51.8182 36.3802,51.747 35.6008,51.6105L 34.1791,59.6694 Z "
            />
            <path
                fill="#000000"
                fillOpacity={0.552941}
                strokeWidth={0.2}
                strokeLinejoin="round"
                d="M 58.6789,45.5265C 59.5337,43.1784 60,40.6437 60,38L 51.8188,38C 51.8188,39.6605 51.5259,41.2526 50.989,42.7274L 58.6789,45.5265 Z "
            />
            <path
                fill="#000000"
                fillOpacity={0.752941}
                strokeWidth={0.2}
                strokeLinejoin="round"
                d="M 41.8209,16.3306C 40.5799,16.1133 39.3032,16 38,16C 36.6968,16 35.4201,16.1133 34.1791,16.3306L 35.6008,24.3895C 36.3802,24.253 37.1821,24.1818 38.0007,24.1818C 38.8192,24.1818 39.6211,24.253 40.4006,24.3895L 41.8209,16.3306 Z "
            />
            <path
                fill="#000000"
                fillOpacity={0.952941}
                strokeWidth={0.2}
                strokeLinejoin="round"
                d="M 24.1825,38C 24.1825,36.3395 24.4754,34.7474 25.0123,33.2726L 17.3211,30.4735C 16.4663,32.8216 16,35.3563 16,38L 24.1825,38 Z "
            />
            <path
                fill="#000000"
                fillOpacity={0.603922}
                strokeWidth={0.2}
                strokeLinejoin="round"
                d="M 60.0096,37.9993C 60.0097,35.5005 59.581,32.9591 58.6768,30.4749L 50.9886,33.2733C 51.5565,34.8336 51.8258,36.4299 51.8257,37.9994L 60.0096,37.9993 Z "
            />
        </svg>
    )
}
