import StopSign from "../Tiles/TrafficSigns/StopSign";
import GiveWaySign from "../Tiles/TrafficSigns/GiveWaySign";
import ParkingSign from "../Tiles/TrafficSigns/ParkingSign";
import CrossWalk from "../Tiles/CrossWalk";
import React from "react";
import Horizontal from "../Tiles/Roads/HorizentalRoad";
import Horizontal1 from "../Tiles/Roads/HorizentalRoad1";
import Vertical from "../Tiles/Roads/VerticalRoad";
import Vertical1 from "../Tiles/Roads/VerticalRoad1";
import Turn1 from "../Tiles/Roads/TurnRoad1";
import Turn2 from "../Tiles/Roads/TurnRoad2";
import Turn3 from "../Tiles/Roads/TurnRoad3";
import Turn4 from "../Tiles/Roads/TurnRoad4";
import Intersection1 from "../Tiles/Roads/IntersectionRoad1";
import Intersection2 from "../Tiles/Roads/IntersectionRoad2";
import Intersection3 from "../Tiles/Roads/IntersectionRoad3";
import Grass from "../Tiles/Grass";
import Water from "../Tiles/Water";
import TrafficLight from "../Tiles/TrafficSigns/TrafficLight";
import Default from "../Tiles/Default";
import BridgeRight from "../Tiles/Bridge/BridgeRight";
import BridgeLeft from "../Tiles/Bridge/BridgeLeft";


// helper function to circumvent js shallow object comparison and allow comparison of arrays nested in objects
export const includesArray = (data: any, arr: any) => {
    return data.some((e: any) => Array.isArray(e) && e.every((o, i) => Object.is(arr[i], o)));
}


export function fetchTile(tile: number) {
    switch (tile) {
        case 1:
            return <Horizontal/>;
        case 2:
            return <Horizontal1/>;
        case 3:
            return <Vertical/>;
        case 4:
            return <Vertical1/>;
        case 5:
            return <Turn1/>;
        case 6:
            return <Turn2/>;
        case 7:
            return <Turn3/>;
        case 8:
            return <Turn4/>;
        case 9:
            return <Intersection1/>;
        case 10:
            return <Intersection2/>;
        case 11:
            return <Intersection3/>;
        case 12:
            return <Grass/>;
        case 13:
            return <Water/>;
        case 14:
            return <StopSign/>;
        case 15:
            return <GiveWaySign/>;
        case 16:
            return <ParkingSign/>;
        case 17:
            return <CrossWalk/>;
        case 18:
            return <TrafficLight state={"off"}/>
        case 19:
            return <BridgeRight/>;
        case 20:
            return <BridgeLeft/>;
        default:
            return <Default/>
    }
}

