import {api_url, id, ws} from "../constantes";

let flagConnection = false;



function subscribe_listener() {
    console.log("en attente de reception")

    // champs dont on veut ecouter les changements
    const listen_doc = {
        fields: [
            "/doc/message",
            "/doc/mode",
            "/doc/pont",
            "/doc/feuNO",
            "/doc/feuNE",
            "/doc/feuSO",
            "/doc/feuSE",
            "/doc/map",
            "/doc/voiture",
            "/doc/incidents",
            "/doc/trafficLights"
        ]
    }

    ws.send(JSON.stringify({
        event: "subscribe_listener",
        data: listen_doc
    }))
}

async function getCityState() {
    console.log("demande l'etat du controle");

    const data = { id: id };

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    };

    try {
        const response = await fetch(api_url + "/iot/aliot/get_doc", requestOptions);
        if (!response.ok) {
            if (response.status === 403) {
                console.error(`While getting the document, request was Forbidden due to permission errors or project missing.`);
            } else if (response.status === 500) {
                console.error(`While getting the document, something went wrong with the ALIVEcode's servers, please try again.`);
            } else {
                console.error(`While getting the document, please try again. ${await response.json()}`);
            }
            return null; // Return null in case of error
        }

        return await response.json();
    } catch (error) {
        console.error('Error fetching mode:', error);
        return null; // Return null in case of error
    }
}

function pingPong(setConnectionFlag:any, setBridge:any, setModeState:any, setMap:any, setTrafficLight:any, setIncidents:any, setCars:any) {
    ws.onmessage = function (d) {
        const data = JSON.parse(d.data);
        if(data.event === "ping"){
            console.log("ping receive sending pong")
            ws.send(JSON.stringify({
                event: "pong",
                data: null,
            }))
        } else if(data.event === "pong") {
            setConnectionFlag(true)
        } else if(data.event === "connect_success") {
            setConnectionFlag(true)
        } else if(data.event === "receive_listen") {
            if(data.data.fields["/doc/pont"]){
                setBridge(data.data.fields["/doc/pont"])
                console.log("updated BRIDGE from network")
            }
            if(data.data.fields["/doc/trafficLights"]){
                setTrafficLight(data.data.fields["/doc/trafficLights"])
                console.log("updated LIGHTS from network")
            }
            if(data.data.fields["/doc/mode"]){
                setModeState(data.data.fields["/doc/mode"])
                console.log("updated MODE from network")
            }
            if(data.data.fields["/doc/map"]){
                setMap(data.data.fields["/doc/map"])
                console.log("updated MAP from network")
            }
            if(data.data.fields["/doc/incidents"]){
                setIncidents(data.data.fields["/doc/incidents"])
                console.log("updated INCIDENTS from network")
            }
            if(data.data.fields["/doc/voiture"]){
                if(setCars !== null){
                    setCars(data.data.fields["/doc/voiture"])
                }
                console.log("updated CARS from network")
            }
        } else if(data.event === "error"){
            console.error(data)
        }
    }
}

function poke() {
    ws.send(JSON.stringify({
        event: "ping",
        data: null,
    }))
}

function reception() {
    ws.onmessage = function (d) {
        const data = JSON.parse(d.data);

        console.log(data);

        switch (data.event) {
            case "connect_success":
                flagConnection = true;

                subscribe_listener();
                getCityState();
                break;
            case "ping":
                console.log("ping receive sending pong")
                ws.send(JSON.stringify({
                    event: "pong",
                    data: null,
                }))
                break;
            case "receive_listen":
                console.log("message changement recu")
                break;


            case "error":
                console.error(data)

        }
    }
}

function updateDoc(field: any) {
    console.log("modification des documents")


    ws.send(JSON.stringify({
        event: "update_doc",
        data: {
            fields: field

        },
    }))

    console.log(field)
}

function updateComp(id: string, value: any) {
    console.log("modification des compoenent");

    ws.send(JSON.stringify({
        event: "update_component",
        data: {
            id: id,
            value: value
        },
    }));
}


function updateBridge(set:any) {
    ws.onmessage = function (d) {
        const data = JSON.parse(d.data);
        if(data.event === "receive_listen"){
            if(data.data.fields["/doc/pont"]){
                set(data.data.fields["/doc/pont"])
            }
        }
    }
}

export {
    ws,
    getCityState,
    updateDoc,
    subscribe_listener,
    pingPong,
    updateBridge,
    updateComp,
    poke
}
