import * as React from "react";
import ConeSvg from "./ConeSvg";
import {IIncidents} from "../Types/Incidents";
import CarSvg from "./CarSvg";
import {ICars} from "../Types/Cars";
import {ITile} from "../Types/Tile";
import Placeholder from "../Tiles/Placeholder";
import {useEffect, useState} from "react";
import {resizeTiles} from "../Types/TileProps";
import Tower from "../Tiles/Tower";
import Triangulation from "./Triangulation";



function MapSvg({map, incidents, cars, showTowersFlag, showTriangulationFlag}: any) {

    const [bigCorner, setBigCorner] = useState<number>(0)
    const [maxX, setMaxX] = useState<number>(0)
    const [maxY, setMaxY] = useState<number>(0)
    const [tileSize, setTileSize] = useState<number>(0)
    const [selectedCar, setSelectedCar] = useState<number>(0)
    const [selectedCarId, setSelectedCarId] = useState<number>(0)
    const [towers, setTowers] = useState<Array<ITile>>([])


    useEffect(() => {
        setTileSize(resizeTiles(map, 0))
        findTowers()
    }, [map])

    useEffect(() => {
        const car = cars ? cars.findIndex((car: ICars) => car.id === selectedCarId) : 0
        setSelectedCar(car >= 0 ? car : 0)
    }, [selectedCarId])

    function dummy(x: number, y: number) {
        console.log(`here: ${x}, ${y}`)
    }

    function findTowers() {
        if(map) {
            const maxX = map.reduce((prev:ITile, current:ITile) => (prev && prev.x > current.x) ? prev : current)
            const minX = map.reduce((prev:ITile, current:ITile) => (prev && prev.x < current.x) ? prev : current)
            const maxY = map.reduce((prev:ITile, current:ITile) => (prev && prev.y > current.y) ? prev : current)
            const minY = map.reduce((prev:ITile, current:ITile) => (prev && prev.y < current.y) ? prev : current)

            const longestSide = maxX.x-minX.x > maxY.y-minY.y ? [minX.x, maxX.x] : [minY.y, maxY.y]

            const towers = [
                {
                    // topLeft
                    id: 0,
                    x: longestSide[0] - 0.25,
                    y: longestSide[0] - 0.5,
                    tile: 0
                },
                {
                    // topRight
                    id: 1,
                    x: longestSide[1] + 0.75,
                    y: longestSide[0] - 0.5,
                    tile: 0
                },
                {
                    // bottomLeft
                    id: 2,
                    x: longestSide[0] - 0.25,
                    y: longestSide[1] + 0.5,
                    tile: 0
                },
                {
                    // bottomRight
                    id: 3,
                    x: longestSide[1] + 0.75,
                    y: longestSide[1] + 0.5,
                    tile: 0
                },
            ]
            setTowers(towers)
            setBigCorner(longestSide[1])
            setMaxX(maxX.x)
            setMaxY(maxY.y)
        }
    }


    return (
        <svg
            width={window.innerWidth * 0.45}
            height={window.innerHeight * 0.65}
            viewBox={`0 ${-tileSize/2} 5000 ${5000 + tileSize/2}`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg">

            <g transform={`translate(${5000/2 - tileSize*((maxX+1)/2)} ${(5000)/2 - tileSize*((maxY+1)/2)})`}>
                {map.map((t: ITile) => {
                    return <Placeholder key={t.id} tile={t} onSelected={dummy} tileSize={tileSize}></Placeholder>
                })}

                {incidents && incidents.map((i: IIncidents) => {
                    return <ConeSvg key={i.id} incident={i} tileSize={tileSize}></ConeSvg>
                })}

                {cars && cars.map((c: ICars) => {
                    return <CarSvg key={c.id} car={c} tileSize={tileSize} setSelectedCarId={setSelectedCarId}></CarSvg>
                })}
            </g>

            {/* render filled tiles */}
            {showTowersFlag && towers.map((t: ITile) => {
                    return <Tower key={t.id} tile={t} onSelected={dummy}
                                  tileSize={tileSize}></Tower>
                })}

            {showTriangulationFlag && cars && <Triangulation a={cars[selectedCar].a} d={cars[selectedCar].d} tilesize={tileSize/2}/>}

        </svg>

    );
}

export default MapSvg;
