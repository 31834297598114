import React, {useState, useEffect, useRef} from 'react';
import picture1 from '../img/VideoCapture_20220310-145245.jpg';
import picture3 from '../img/N_cars2-1152x1536.jpg';
import picture4 from '../img/equipe1-2048x1152.jpg';
import ville_c from '../img/ville_c.jpg';
import footerImage from "../img/footer_1.png";
import personne from '../img/personne.jpg';
import presentation from '../img/presentation.jpg'
import photo_ville_v2 from '../img/photo_groupe_portrait-2048x1536.jpg'
import BuildingSvg from "../comps/BuildingSvg";
import CitySvg from "../comps/CitySvg";
import {useTranslation} from "react-i18next";
import Medal from "../comps/Medal";
import {articles} from "../constantes";
import {Article} from "../comps/Article"

const PresentationPage: React.FC = () => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const images = [presentation, picture1, picture3, picture4, ville_c, personne];
    const { t, i18n } = useTranslation();

    // Create a list of image elements
    const imageElements = images.map((image, index) => (
        <img
            key={index}
            src={image}
            alt={`Picture ${index + 1}`}
            className={`scroller__item w-[200px] h-[200px] rounded-lg object-cover`}
        />
    ));

    // Duplicate the image elements for infinite scrolling
    const duplicatedImages = images.map((image, index) => (
        <img
            key={index + images.length}
            src={image}
            alt={`Picture ${index + 1}`}
            className={`scroller__item w-[200px] h-[200px] rounded-lg object-cover`}
        />
    ));

    const videoRef = useRef<HTMLVideoElement>(null);

    useEffect(() => {
        if (videoRef.current && videoRef.current.readyState >= 1) {
            videoRef.current.play().catch(error => {
                console.error('Autoplay was prevented:', error);
            });
        }
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImageIndex(prevIndex => (prevIndex + 1) % images.length);
        }, 3000);

        // Cleanup function to clear interval
        return () => clearInterval(interval);
    }, [images.length]);

    useEffect(() => {
        if (i18n.language === 'ar') {
            document.body.dir = 'rtl';
        } else {
            document.body.dir = 'ltr';
        }
        //document.body.style.overflow = 'hidden'; // Set overflow to hidden
        return () => {
            document.body.style.overflow = 'auto'; // Reset overflow when component unmounts
        };
    }, [i18n.language]);

    return (
        <div className="flex flex-col h-screen overflow-y-scroll">
            {/* Introduction */}
            <div className="w-full flex flex-col mb-28">
                <div className="flex flex-col relative items-center">
                <video className="w-full object-cover" src="https://lrima.cmaisonneuve.qc.ca/wp-content/uploads/2020/02/focus.mp4" autoPlay={true} loop={true} muted={true}></video>
                    <div className="w-full flex flex-col items-center h-52 tablet:h-32 destkop:h-40 absolute top-44 laptop:top-44 desktop:top-28 bg-black bg-opacity-40 backdrop-blur-[2px]">
                        <div className="text-white text-center text-xs tablet:text-sm laptop:text-lg desktop:text-xl">

                        <h1 className="text-8xl font-bold mb-6">
                        <span className="inline-block">AliveC</span>
                        <span className="inline-block"><BuildingSvg /></span>
                        <span className="inline-block">ty</span>
                    </h1>
                    <p className="text-lg">
                        {t('small_title')}
                    </p>

                        </div>
                    </div>
                </div>
            </div>

            {/* Objectives */}
            <div className="flex flex-row h-1/2">
                {/* Left side */}
                <div className="w-1/2 flex justify-center items-center mb-28">
                    <CitySvg/>
                </div>

                {/* Right side */}
                <div className="w-1/2 flex flex-col justify-center mb-28">
                    <h1 className="text-4xl font-bold mb-4">{t('objective.title')}</h1>
                    <ul className="text-lg text-gray-700 px-8 list-disc">
                        <li>{t('objective.objective1')}</li>
                        <li>{t('objective.objective2')}</li>
                    </ul>
                </div>

            </div>

            {/* Image scroller */}
            <div className="flex flex-row h-1/2">
                <div className="w-full relative">
                    <div className="scroller" data-animated="true">
                        <div className="scroller__inner flex justify-center items-center mb-28">
                            {imageElements}
                            {duplicatedImages}
                            {imageElements}
                            {duplicatedImages}
                        </div>
                    </div>
                </div>
            </div>

            {/* Prizes Won */}
            <div className="flex flex-col items-center mb-28">
                <h1 className="text-4xl font-bold mb-4">{t('prize.title')}</h1>
                <ul className="text-lg text-gray-700 px-8 list-disc">
                    <div className="mb-2">
                        <span className="inline-block align-middle mr-2">
                            <Medal />
                        </span>
                        {t('prize.prize1')}

                    </div>

                    <div className="mb-2">
                        <span className="inline-block align-middle mr-2">
                            <Medal />
                        </span>
                        {t('prize.prize2')}
                    </div>
                </ul>
            </div>

            {/* Versions */}
            <h1 className="flex justify-center items-center text-4xl font-bold mb-12">{t('versions.title')}</h1>
            <div className="flex flex-row h-1/2 mb-28">
                <div className="w-full flex flex-col justify-center items-start p-12">
                    <h1 className="text-4xl font-bold">{t('versions.version_beta')}</h1>
                    <h2 className='text-2xl'>{t('versions.desc_beta')}</h2>
                </div>
                <div className="w-full flex justify-center items-center">
                    <video src="https://lrima.cmaisonneuve.qc.ca/wp-content/uploads/2020/02/focus.mp4" autoPlay={true} loop={true} muted={true}></video>
                </div>
            </div>

            <div className="flex flex-row h-1/2 mb-28">
                <div className="w-full flex justify-center items-center">
                    <img src={photo_ville_v2} alt="Description of Picture" className="w-[600px] h-[400px] object-cover"/>
                </div>
                <div className="w-full flex flex-col justify-center items-start">
                    <h1 className="text-4xl font-bold object-center">{t('versions.version_1')}</h1>
                    <h2 className='text-2xl'>{t('versions.desc_1')}</h2>
                </div>

            </div>

            <div className="flex flex-row h-1/2 mb-28">
                <div className="w-full flex flex-col justify-center items-start p-12">
                    <h1 className="text-4xl font-bold">{t('versions.version_2')}</h1>
                    <h2 className='text-2xl'>{t('versions.desc_2')}</h2>
                </div>
                <div className="w-full flex justify-center items-center">
                    <img src={picture1} alt="Description of Picture" className="w-[600px] h-[400px] object-cover"/>
                </div>
            </div>

            <div className="flex flex-wrap justify-center p-8 gap-7 mb-28">
                {articles.map((article, index) => (
                    <Article
                        key={index}
                        img={article.img}
                        title={article.title}
                        link={article.link}
                        hashtags={article.hashtags}
                    />
                ))}
            </div>

            <div className="w-screen">
                <div className="flex flex-col relative items-center">
                    <img
                        className="w-full object-cover object-bottom"
                        src={footerImage}
                    />
                    <div className="w-full h-56 flex flex-col items-center h-[100px] tablet:h-44 laptop:h-[260px] absolute bottom-0">
                        <div className="text-[#FEFDED] text-center mt-5 mp-5 text-4xl">
                            Copyright © 2024 - All right reserved
                        </div>

                    </div>
                </div>
            </div>

        </div>
    );
};

export default PresentationPage;
