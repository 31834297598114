import * as React from "react";


function CrossWalk() {

    return (
        <svg

            viewBox="0 0 757 246"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">

               <rect width="72" height="246" fill="#F5F5F5"/>
                <rect x="685" width="72" height="246" fill="#F5F5F5"/>
                <rect x="137" width="72" height="246" fill="#F5F5F5"/>
                <rect x="548" width="72" height="246" fill="#F5F5F5"/>
                <rect x="411" width="72" height="246" fill="#F5F5F5"/>
                <rect x="274" width="72" height="246" fill="#F5F5F5"/>

        </svg>
    );
}

export default CrossWalk;
