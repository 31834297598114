import React, {Suspense, useEffect, useState, ReactNode} from 'react';
import {BrowserRouter as Router, Route, Routes, Link, NavLink} from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import './index.css';
import PresentationPage from "./Pages/PresentationPage";
import {App} from './App';
import ConnectionPage from './Pages/ConnectionPage';
import GlobeIconSvg from "./comps/GlobeIconSvg";
import reportWebVitals from './reportWebVitals';
import MapConfig from "./Pages/MapConfig";
import SVGComponent from "./comps/AliveCodeSvg";
import i18next, {t} from 'i18next';
import {initReactI18next, useTranslation} from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import {ILanguage} from "./Types/Languages";
import '/node_modules/flag-icons/css/flag-icons.min.css';
import cookies from 'js-cookie';
import LRIMa from './img/LRIMa.png';



const languages: ILanguage[] = [
    {
        code: 'en',
        name: 'English',
        country_code: 'ca',
    },
    {
        code: 'fr',
        name: 'Français',
        country_code: 'fr',
    },
    {
        code: 'ar',
        name: 'العربية',
        country_code: 'tn',
        dir: 'rtl',
    },
];

i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    .use(HttpApi)
    .init({
        supportedLngs: ['en', 'fr', 'ar'],
        fallbackLng: "en",
        interpolation: {escapeValue: false},
        detection: {
            order: ['cookie', 'htmlTag', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
            caches: ['cookie']
        },
        backend: {
            loadPath: 'assests/locales/{{lng}}/translation.json'
        },
        react: {useSuspense: false},
        lng: cookies.get('i18next') || 'en'
    });

const loadingMarkup = (
    <h3>Loading..</h3>
)


const Dropdown: React.FC<{ languages: ILanguage[] }> = ({languages}) => {
    const [selectedLanguage, setSelectedLanguage] = useState<ILanguage | null>(null);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        const currentLanguageCode = cookies.get('i18next') || 'en';
        const language = languages.find((l) => l.code === currentLanguageCode);
        setSelectedLanguage(language || null);
    }, [languages]);

    const handleLanguageChange = (language: ILanguage) => {
        setSelectedLanguage(language);
        setIsOpen(false);
        i18next.changeLanguage(language.code);
        cookies.set('i18next', language.code);
    };

    return (
        <>
            <span className="rounded-md shadow-sm">
                <button
                    type="button"
                    className="inline-flex justify-center rounded-md border border-gray-300
                    px-4 py-3 bg-white text-sm font-medium text-gray-700
                    focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    onClick={() => setIsOpen(!isOpen)}>
                    <GlobeIconSvg/>
                    <svg
                        className="-mr-1 ml-2 h-5 w-5"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                    >
                        <path
                            fillRule="evenodd"
                            d="M10 12a1 1 0 0 1-.707-.293l-4-4a1 1 0 1 1 1.414-1.414L10 10.586l3.293-3.293a1 1 0 1 1 1.414 1.414l-4 4A1 1 0 0 1 10 12z"
                            clipRule="evenodd"
                        />
                    </svg>
                </button>
            </span>

            {isOpen && (
                <div
                    className="origin-top-right absolute mt-2 w-max rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                    <div role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                        {languages.map((language) => (
                            <button
                                key={language.code}
                                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 text-left"
                                role="menuitem"
                                onClick={() => handleLanguageChange(language)}
                            >
                                <span className={`fi fi-${language.country_code} fis`}></span>
                                {language.name}
                            </button>
                        ))}
                    </div>
                </div>
            )}
        </>
    );
};


const Navbar: React.FC = () => {
    const {t} = useTranslation();
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        // Check if there is a token in localStorage
        const token = localStorage.getItem('collected_token');
        if (token) {
            setIsLoggedIn(true);
        } else {
            setIsLoggedIn(false);
        }
    }, []);

    const id = localStorage.getItem('collected_id');
    const token = localStorage.getItem('collected_token');

    const handleLogout = () => {
        // Remove localStorage keys
        localStorage.removeItem('collected_id');
        localStorage.removeItem('collected_token');
        setIsLoggedIn(false);
        window.location.href = `/connection?id=${id}&token=${token}`
    };


    return (
        <nav className="bg-gray-100 px-4 py-3 flex justify-between items-start h-16">
            {/* Logo */}
            <NavLink to={`https://alivecode.ca/`} className="flex items-center text-lg font-bold">
                <img src={LRIMa} alt="Image" className={'w-40 h-10'} />
                <span className="ml-2">{t('alive_city')}</span>
            </NavLink>

            <div className="flex flex-row items-center justify-between space-x-2">
                {/* Connection link */}
                <div className="">
                    {isLoggedIn ? (
                        <button onClick={handleLogout} className="inline-flex justify-center w-full
                                rounded-md border border-gray-300
                                px-4 py-3 bg-white text-sm font-medium
                                text-gray-700 focus:outline-none focus:ring-2
                                focus:ring-blue-500 focus:border-blue-500">
                            {t('buttons.logout')}
                        </button>
                    ) : (
                        <NavLink to={`/connection?id=${id}&token=${token}`} type="button"
                                 className="inline-flex justify-center w-full
                                rounded-md border border-gray-300
                                px-4 py-3 bg-white text-sm font-medium
                                text-gray-700 focus:outline-none focus:ring-2
                                focus:ring-blue-500 focus:border-blue-500">
                            {t('buttons.connection')}
                        </NavLink>
                    )}
                </div>

                {/* Map Link */}
                <div className="">
                    {isLoggedIn && (
                        <NavLink to={`/main`} type="button"
                                 className="inline-flex justify-center w-full
                            rounded-md border border-gray-300
                            px-4 py-3 bg-white text-sm font-medium
                            text-gray-700 focus:outline-none focus:ring-2
                            focus:ring-blue-500 focus:border-blue-500">
                            {t('buttons.map')}
                        </NavLink>
                    )}
                </div>


                <div className="">
                    <span className="rounded-md shadow-sm">
                        <NavLink to={`/`}
                                 type="button"
                                 className="inline-flex justify-center w-full
                                    rounded-md border border-gray-300
                                    px-4 py-3 bg-white text-sm font-medium
                                    text-gray-700 focus:outline-none focus:ring-2
                                    focus:ring-blue-500 focus:border-blue-500">
                            {t('buttons.about')}
                        </NavLink>
                    </span>
                </div>

                {/* Dropdown menu */}
                <div className="">
                    <Dropdown languages={languages}/>
                </div>

            </div>
        </nav>
    );
};

export default Navbar;


// @ts-ignore

const AppContainer: React.FC<{ children: ReactNode }> = ({children}) => (
    <div className="h-screen bg-gray-100">
        {children}
    </div>
);


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
// @ts-ignore
root.render(
    <Suspense fallback={loadingMarkup}>
        <Router>
            <AppContainer>
                <div className="h-screen overflow-y-hidden">
                    <Navbar/>
                    <Routes>
                        <Route path="/connection" element={<ConnectionPage/>}/>
                        <Route path="/" element={<PresentationPage/>}/>
                        <Route path="/main" element={<App/>}/>
                        <Route path="/mapConfig" element={<MapConfig/>}/>
                    </Routes>
                </div>
            </AppContainer>
        </Router>
    </Suspense>
);

reportWebVitals();
